import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Typography } from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import clsx from 'clsx';
import AppButton from '../button/AppButton';
import {
  white, cyan, endeavour, stratos, hoverButtonShadow,
} from '../../colors';

const useStyles = makeStyles(() => ({
  wrapper: {
    width: '59%',
    height: 'auto',
    display: 'flex',
    alignItems: 'center',
    left: '20.5%',
    bottom: 0,
    position: 'fixed',
    zIndex: '15',
    borderRadius: '0 50px 0 0',
    flexDirection: 'column',
    backgroundColor: 'white',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.47)',
    '& p': {
      fontStyle: 'italic',
      '& span': {
        color: endeavour,
        display: 'inline',
        fontStyle: 'italic',
      },
    },
    '& span': {
      display: 'block',
    },
    '& hr': {
      height: '4px',
      color: stratos,
      backgroundColor: stratos,
      width: '10%',
      marginBottom: '2%',
      marginTop: '1.8%',
    },
    '@media (orientation:landscape) and (max-device-width:900px)': {
      width: '80%',
      left: '10%',
    },
    '@media (orientation: portrait)': {
      width: '90%',
      left: '5%',
      height: 'auto',
      '& hr': {
        height: '3px',
        width: '15vmin',
      },
    },
  },
  title: {
    marginTop: '4.8%',
    '& h6': {
      color: endeavour,
    },
  },
  descriptionBox: {
    position: 'relative',
    height: '38vh',
    width: '83.2%',
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    marginTop: '4rem',
    '@media (orientation:landscape) and (max-device-width:900px)': {
      marginTop: '0',
    },
  },
  description: {
    width: '100%',
    height: '100%',
    overflow: 'auto',
    textAlign: 'left',
    '& h6': {
      fontWeight: 'normal',
      textAlign: 'left',
      '& p': {
        fontWeight: 'bold',
        textAlign: 'left',
        display: 'block',
        fontStyle: 'normal',
        margin: '1rem 0',
      },
    },
    '& span': {
      fontWeight: 'bold',
      textAlign: 'left',
      display: 'block',
      fontStyle: 'normal',
      textTransform: 'uppercase',
      margin: '1rem 0',
    },
    '& hr': {
      height: '0.125rem',
      color: stratos,
      backgroundColor: stratos,
      width: '40%',
      display: 'block',
      marginLeft: 0,
      marginTop: '0',
      marginBottom: '0.8rem',
    },
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '-ms-overflow-style': 'none',
    scrollbarWidth: 'none',
  },
  boxShadow: {
    height: '1rem',
    width: '105%',
    background: '#fff 0% 0% no-repeat padding-box',
    filter: 'blur(10px)',
    bottom: 0,
    marginBottom: 0,
    padding: 0,
    marginLeft: '-2.5%',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '5%',
    marginRight: '8.4%',
    marginBottom: '5%',
    alignSelf: 'flex-end',
    '& button': {
      marginLeft: '1.938rem',
    },
    '@media (orientation:landscape) and (max-device-width:900px)': {
      marginBottom: '3%',
      marginTop: '3%',
    },
  },
  toggleWrapper: {
    position: 'absolute',
    right: '8.4%',
    top: '12.58%',
  },
  toggle: {
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    border: '2px solid #00002E',
    borderRadius: '20px 20px 20px 20px',
    alignItems: 'center',
    zIndex: '5',
    overflow: 'hidden',
    position: 'relative',
    backgroundClip: 'padding-box',
    webkitBackgroundClip: 'padding-box',
    backgroundColor: stratos,
    '& p': {
      padding: '0.45rem',
      fontStyle: 'normal',
    },
  },
  whiteBackground: {
    color: stratos,
    backgroundColor: white,
  },
  blackBackground: {
    color: white,
    backgroundColor: stratos,
  },
  closeIcon: {
    position: 'absolute',
    right: '4.50%',
    top: '4.50%',
    width: 'auto',
    cursor: 'pointer',
    backgroundColor: 'transparent',
    border: '0',
    '& svg': {
      fontSize: '1.813rem',
    },
  },
  heightWhenNoButtons: {
    height: '60vh !important',
  },
}));

const CookiePolicy = ({
  changeView,
  language,
  changeLanguage,
  content,
  title,
  subtitle,
  displayButtons,
  paddingTopTitle,
  heightContent,
  acceptCookies,
}) => {
  const classes = useStyles({ paddingTopTitle, heightContent });

  return (
    <div className={classes.wrapper}>
      <button
        type="button"
        className={classes.closeIcon}
        onClick={() => changeView(true, false)}
      >
        <HighlightOffIcon />
      </button>
      <div className={classes.toggleWrapper}>
        <div className={classes.toggle}>
          <Typography
            className={language === 'ro' ? classes.blackBackground : classes.whiteBackground}
            onClick={() => {
              changeLanguage('ro');
            }}
          >
            RO
          </Typography>
          <Typography
            className={language === 'en' ? classes.blackBackground : classes.whiteBackground}
            onClick={() => {
              changeLanguage('en');
            }}
          >
            EN
          </Typography>
        </div>
      </div>
      <div className={classes.title}>
        <Typography variant="subtitle2">
          {title}
        </Typography>
      </div>
      <hr />
      {subtitle
        && (
          <Typography variant="body1" paragraph>
            {subtitle}
            {' '}
            <Typography variant="body1" component="span">2022-08-23</Typography>
          </Typography>
        )}

      <div className={clsx(classes.descriptionBox, !displayButtons && classes.heightWhenNoButtons)}>
        <div className={classes.description}>
          {content}
        </div>
        <div className={classes.boxShadow} />
      </div>
      {displayButtons
        && (
          <div className={classes.buttons}>
            <AppButton
              background={white}
              backgroundOnHover={stratos}
              color={stratos}
              colorOnHover={white}
              border={`2px solid ${stratos}`}
              borderHover={`2px solid ${stratos}`}
              callback={() => changeView(true, false)}
            >
              {language === 'ro' ? 'Refuza'
                : 'Decline'}
            </AppButton>
            <AppButton
              background={cyan}
              color={white}
              colorOnHover={white}
              backgroundOnHover={cyan}
              buttonHoverShadow={`0px 5px 10px ${hoverButtonShadow}`}
              buttonFocusShadow={`0px 7px 15px ${stratos}`}
              callback={() => acceptCookies()}
            >
              {language === 'ro' ? 'accepta'
                : 'accept'}
            </AppButton>
          </div>
        )}
    </div>
  );
};
CookiePolicy.propTypes = {
  changeView: PropTypes.func.isRequired,
  language: PropTypes.string.isRequired,
  content: PropTypes.element.isRequired,
  changeLanguage: PropTypes.func.isRequired,
  acceptCookies: PropTypes.func,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  paddingTopTitle: PropTypes.string,
  heightContent: PropTypes.string,
  displayButtons: PropTypes.bool,
};
CookiePolicy.defaultProps = {
  subtitle: '',
  displayButtons: false,
  paddingTopTitle: '4vw',
  heightContent: '70vw',
  acceptCookies: null,
};
export default CookiePolicy;

import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Typography } from '@material-ui/core';
import AppButton from '../button/AppButton';
import {
  white, endeavour, cyan, hoverButtonShadow, stratos,
} from '../../colors';
import cookieBanner from '../../assets/images/cookieBanner.svg';

const useStyles = makeStyles(() => ({
  wrapper: {
    width: '100%',
    height: '14.438rem',
    display: 'flex',
    alignItems: 'center',
    bottom: 0,
    position: 'fixed',
    zIndex: '15',
    flexDirection: 'column',
    backgroundColor: endeavour,
    boxShadow: '0px -10px 20px #00002EA0',
    '@media (orientation:landscape) and (max-device-width:900px)': {
      height: '10rem',
    },
    '@media (orientation:portrait)': {
      height: '15rem',
      '@media (max-width:600px)': {
        height: '10rem',
      },
    },
  },
  cookieContainer: {
    width: '60%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '@media (orientation:landscape) and (max-device-width:900px)': {
      width: '90%',
    },
    '@media (orientation:landscape) and (min-device-width:1200px) and (min-width:600px) and (max-width:1300px)': {
      width: '70%',
    },
    '@media (orientation:portrait)': {
      width: '70%',
      flexDirection: 'column',
      justifyContent: 'space-evenly',
      '@media (max-width:600px)': {
        width: '90%',
      },
    },
  },
  cookieTopBox: {
    display: 'flex',
    width: '60%',
    alignItems: 'center',
    '@media (orientation:portrait)': {
      width: '100%',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  description: {
    textAlign: 'center',
    color: white,
  },
  title: {
    width: 'auto',
    marginLeft: '15%',
    '@media (orientation:landscape) and (max-width:1600px)': {
      marginLeft: '5.8%',
    },
    '@media (orientation:portrait)': {
      marginLeft: '5%',
    },
    '& h6': {
      color: white,
      marginBottom: '5%',
      fontWeight: 'bold',
    },
    '& p': {
      width: '90%',
      textAlign: 'left',
    },
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: 'auto',
    '@media (orientation: portrait)': {
      justifyContent: 'space-evenly',
    },
    '& button': {
      '@media (orientation:portrait)': {
        marginLeft: '0',
      },
      '@media (orientation:landscape) and (max-width:1200px)': {
        marginLeft: '1rem',
      },
      '@media (orientation:landscape) and (max-device-width:900px)': {
        marginLeft: '1rem',
      },
    },
    '@media (orientation:portrait)': {
      width: '80%',
      justifyContent: 'space-evenly',
    },
  },
}));

const CookieBanner = ({ changeView, acceptCookies }) => {
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <div className={classes.cookieContainer}>
        <div className={classes.cookieTopBox}>
          <img src={cookieBanner} alt="cookieBanner" className={classes.image} />
          <div className={classes.title}>
            <Typography variant="subtitle2">
              Don’t you just love cookies?
            </Typography>
            <Typography variant="body1" className={classes.description}>
              We use functional cookies to ensure that you get the best experience on our website.
            </Typography>
          </div>
        </div>
        <div className={classes.buttons}>
          <AppButton
            background="transparent"
            backgroundOnHover="transparent"
            color={white}
            colorOnHover={white}
            callback={() => changeView(true, true)}
          >
            Learn more
          </AppButton>
          <AppButton
            background={cyan}
            backgroundOnHover={cyan}
            color={white}
            colorOnHover={white}
            callback={() => acceptCookies()}
            buttonHoverShadow={`0px 5px 10px ${hoverButtonShadow}`}
            buttonFocusShadow={`0px 7px 15px ${stratos}`}
          >
            accept
          </AppButton>
        </div>
      </div>

    </div>

  );
};
CookieBanner.propTypes = {
  changeView: PropTypes.func.isRequired,
  acceptCookies: PropTypes.func.isRequired,
};
export default CookieBanner;

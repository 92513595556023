import React from 'react';
import { Typography, makeStyles, useMediaQuery } from '@material-ui/core';
import PropTypes from 'prop-types';
import { white } from '../../colors';

const useStyles = makeStyles(() => ({
  card: {
    width: '25%',
    position: 'relative',
    overflow: 'hidden',
    '@media (max-width:600px)': {
      width: '19.063rem',
      height: '24.438rem',
    },
    '@media(orientation: portrait) and (min-width: 600px)': {
      height: '27.438rem',
      width: '21.375rem',
    },
    '@media (orientation: portrait)': {
      flex: 'none',
      display: 'flex',
      position: 'relative',
      transition: '900ms ease 0ms',
    },
    '@media (orientation: landscape)': {
      height: '32vw',
      '@media(max-width: 1366px)': {
        height: '35vw',
      },
      '@media(max-width: 1080px)': {
        height: '45vw',
        width: '20rem',
        flex: 'none',
      },
      '@media(max-width: 900px)': {
        height: '50vw',
      },
      '&:hover': {
        h3: {
          marginTop: '35%',
        },
        '& p': {
          transitionDuration: ' 0.6s',
          opacity: '1',
          paddingTop: '6vh',
        },
        '& #blur': {
          '@supports ((-webkit-backdrop-filter: blur(14px)) or (backdrop-filter: blur(14px)))': {
            webkitBackdropFilter: 'blur(14px)',
            backdropFilter: 'blur(14px)',
          },
          '@supports not ((-webkit-backdrop-filter: blur(14px)) or (backdrop-filter: blur(14px)))': {
            backgroundColor: 'rgba(255, 255, 255, .2)',
          },
        },
        '& img': {
          opacity: '0.8',
          transform: ' scale3d(1.1, 1.1, 1)',
          zIndex: '0',
        },
      },
    },
  },
  column: {
    color: white,
    zIndex: 2,
    transition: '900ms ease 0ms',
    backgroundPosition: 'center bottom',
    backgroundSize: ' 100% auto',
    position: 'absolute',
    height: '100%',
    width: '100%',
    top: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '0 3vw',
    '@media (orientation: portrait)': {
      position: 'absolute',
    },
    '@media (max-width:600px)': {
      height: '24.438rem',
      padding: '0 11.47%',
      marginTop: '24.80%',
    },
    '@media(orientation: portrait) and (min-width: 600px)': {
      padding: '0 15.78%',
      marginTop: '26.42%',
      height: '27.438rem',
    },
    '& h3,h6,p': {
      color: white,
    },
    '& p': {
      paddingTop: '10%',
    },

    '&:hover': {
      transform: 'translate(0, -10%)',
    },
  },
  image: {
    width: '100%',
    height: '100%',
    transition: '0.6s',
  },
  title: {
    color: `${white} !important`,
    fontWeight: '500 !important',
    transition: '0.6s',
    textAlign: 'center',
    '@media(orientation: landscape)': {
      paddingTop: '40%',
    },
    '@media(orientation: portrait)': {
      paddingBottom: '2vh',
      height: '20%',
      '@media (max-width:600px)': {
        height: '15%',
      },
      '@media(orientation: portrait) and (min-width: 600px)': {
        height: '20%',
      },
    },
  },
  subtitle: {
    transition: '0.6s',
    '@media (orientation: portrait)': {
      paddingTop: '0',
    },
    '@media (orientation: landscape)': {
      paddingTop: '10%',
    },
  },
  description: {
    '@media (orientation: portrait)': {
      padding: '0',
      opacity: '1',
      top: '10%',
    },
    '@media (max-width:600px)': {
      fontWeight: 'normal !important',
    },
    '@media (min-width:600px) and (orientation: portrait)': {
      fontWeight: 'initial',
    },
    '@media (orientation: landscape)': {
      opacity: '0',
      transition: '0.6s',
    },
  },
  blur: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    zIndex: 1,
  },
}));

const MissionCard = ({
  title, subtitle, description, image, mobileImage, alt,
}) => {
  const classes = useStyles();
  const tabletOrientation = useMediaQuery('@media (orientation: portrait)');

  return (
    <div className={classes.card}>
      <div id="blur" className={classes.blur} />
      <img src={tabletOrientation ? mobileImage : image} alt={alt} className={classes.image} />
      <div className={classes.column}>
        {title && (
          <Typography variant="h3" className={classes.title}>
            {title}
          </Typography>
        )}
        {subtitle && (
          <Typography variant="subtitle1" className={classes.subtitle}>
            {subtitle}
          </Typography>
        )}
        {description && (
          <Typography variant="body1" className={classes.description}>
            {description}
          </Typography>
        )}
      </div>
    </div>
  );
};

MissionCard.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string.isRequired,
  mobileImage: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
};

MissionCard.defaultProps = {
  title: null,
  subtitle: null,
  description: null,
};

export default MissionCard;

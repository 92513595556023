import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Typography } from '@material-ui/core';
import { fruitSalad, white } from '../../../colors';

const useStyles = makeStyles({
  root: {
    background: fruitSalad,
    display: 'flex',
    padding: '17.6vh 0 15.6vh 0',
    flexDirection: 'column',
    alignItems: 'center',

    '@media(orientation: portrait)': {
      padding: '8vh 0 5vh 0',
    },

    '& *': {
      color: white,
    },
  },
  description: {
    padding: '5.5vh 24.6vw 0 24.6vw',
    textAlign: 'left',
    columnCount: 2,
    columnFill: 'balance',
    columnGap: '2.5vw',

    '@media(orientation: portrait) and (max-width: 600px)': {
      columnCount: 1,
      textAlign: 'center',
    },

    '@media(orientation: portrait)': {
      padding: '5.5vh 10%',
    },
  },
});

function RequiredSkills({ job }) {
  const classes = useStyles();

  const requiredSkillsSentences = useMemo(() => job?.requiredSkillsDescription.split('\n'), [job?.requiredSkillsDescription]);

  return (
    <div className={classes.root}>
      <Typography variant="h3">{job.requiredSkillsTitle}</Typography>
      <div className={classes.description}>
        {requiredSkillsSentences.map((sentence, index) => (
          sentence?.trim() !== '' && (
            // eslint-disable-next-line react/no-array-index-key
            <Typography key={`required-skills-${index}`} variant="body1">{`${sentence}`}</Typography>
          )
        ))}
      </div>
    </div>
  );
}

RequiredSkills.propTypes = {
  job: PropTypes.shape({
    requiredSkillsTitle: PropTypes.string,
    requiredSkillsDescription: PropTypes.string,
  }).isRequired,
};

export default RequiredSkills;

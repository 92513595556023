import React from 'react';
import propTypes from 'prop-types';
import { endeavour } from '../../colors/index';

const Border = ({ className, children }) => (
  <div className={className}>
    {children}
    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 735.899 731.217">
      <g id="Artwork_14" data-name="Artwork 14" transform="translate(367.95 365.608)">
        <g id="Group_414" data-name="Group 414" transform="translate(-367.95 -365.608)">
          <path
            id="Path_3641"
            className="borderClass"
            data-name="Path 3641"
            d="M4.5,138.42V7.5H652.91c42.58,0,77.09,34.51,77.09,77.09V728.5H4.5v-118"
            fill="none"
            stroke={endeavour}
            strokeWidth="15"
          />
        </g>
      </g>
    </svg>

  </div>
);

Border.propTypes = {
  className: propTypes.string.isRequired,
  children: propTypes.node,
};

Border.defaultProps = {
  children: null,
};
export default Border;

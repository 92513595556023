import React, {
  useEffect, useMemo, useRef, useState,
} from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useMediaQuery } from '@mui/material';
import bonusSkillsIcon from '../../../assets/images/bonus-skills-icon.svg';
import Border from '../../Who/Border';

const useStyles = makeStyles({
  root: {
    padding: '12.5vh 20%',
    display: 'flex',

    '@media(orientation: portrait)': {
      padding: '9.5vh 0 9.5vh 10%',
    },

    '@media(orientation: portrait) and (max-width: 600px)': {
      padding: '6vh 10%',
    },
  },
  textWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    flex: '0.7',

    '@media(orientation: portrait) and (max-width: 600px)': {
      flex: '1',
    },
  },
  title: {
    fontWeight: 'bold',
    paddingBottom: '2vh',
  },
  image: {
    position: 'absolute',
    top: '50%',
    transform: 'translate3d(-42%, -50%, 0)',
    animationName: '$imageAnimation',
    animationDuration: '2s',
    animationTimingFunction: 'ease-in-out',
    width: '11vmax',
    height: '11vmax',

    '@media(orientation: portrait)': {
      width: '7vmax',
      height: '7vmax',
    },
  },
  '@keyframes imageAnimation': {
    '0%': {
      opacity: 0,
    },
    '100%': {
      opacity: 1,
    },
  },
  imageBorder: {
    width: '20vw',
    zIndex: '0',
    strokeDasharray: '3452',
    strokeDashoffset: '0',
    animation: '$dash 3s linear',
    animationFillMode: 'forwards',
    position: 'relative',
  },
  hideElement: {
    visibility: 'hidden',
    transform: 'translateY(100vh)',
  },
  '@keyframes dash': {
    from: {
      strokeDashoffset: '3452',
    },
    to: {
      strokeDashoffset: '0',
    },
  },
  imageBorderWrapper: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
    position: 'relative',
  },
});

function RequiredSkillsBonus({ job }) {
  const classes = useStyles();
  const ref = useRef(null);
  const [isInView, setIsInView] = useState(false);
  const isMobile = useMediaQuery('@media(orientation: portrait) and (max-width: 600px)');
  const descriptionSentences = useMemo(() => job?.requiredSkillsBonusDescription.split('\n'), [job?.requiredSkillsBonusDescription]);

  useEffect(() => {
    const visibilityObserver = new IntersectionObserver((entry) => {
      if (entry[0].isIntersecting) {
        setIsInView(true);
        visibilityObserver.disconnect();
      }
    }, {
      threshold: 0.25,
    });
    visibilityObserver.observe(ref.current);
  }, []);

  return (
    <div className={classes.root} ref={ref}>
      <div className={classes.textWrapper}>
        <Typography variant="body1" className={classes.title}>
          {job.requiredSkillsBonusTitle}
        </Typography>
        {descriptionSentences.map((sentence, index) => (
          sentence?.trim() !== '' && (
            // eslint-disable-next-line react/no-array-index-key
            <Typography key={`bonus-${index}`} variant="body1">{`${sentence}`}</Typography>
          )
        ))}
      </div>

      {!isMobile && (
        <div className={classes.imageBorderWrapper}>
          <Border className={isInView ? classes.imageBorder : classes.hideElement}>
            <img src={bonusSkillsIcon} alt="skills" className={classes.image} />
          </Border>
        </div>
      )}
    </div>
  );
}

RequiredSkillsBonus.propTypes = {
  job: PropTypes.shape({
    requiredSkillsBonusTitle: PropTypes.string,
    requiredSkillsBonusDescription: PropTypes.string,
  }).isRequired,
};

export default RequiredSkillsBonus;

import React, { useCallback } from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core';
import smoothscroll from 'smoothscroll-polyfill';
import UpArrowButton from '../../assets/images/up-button.svg';
import { white, gallery, silver } from '../../colors';

smoothscroll.polyfill();
const styles = () => ({
  button: {
    width: '3.8rem',
    height: '3.8rem',
    borderRadius: '1rem',
    right: '10rem',
    background: white,
    top: '45%',
    '&:hover': {
      cursor: 'pointer',
      background: gallery,
    },
    '&:click': {
      cursor: 'pointer',
      background: silver,
    },
    position: 'absolute',
    padding: '0',
    zIndex: '2',
    '@media(orientation: portrait) and (max-width: 600px)': {
      width: '1.75rem',
      height: '1.75rem',
      top: '-5%',
      minWidth: 'auto',
      borderRadius: '0.5rem',
      right: '2rem',
    },
    '@media(orientation: portrait) and (min-width: 600px)': {
      width: '2.375rem',
      height: '2.375rem',
      borderRadius: '0.5rem',
      top: '-6%',
      minWidth: 'auto',
      right: '2rem',
    },
    '@media(max-width : 1180px)  and (orientation : landscape)': {
      width: '2.375rem',
      height: '2.375rem',
      minWidth: 'auto',
      borderRadius: '0.5rem',
      right: '1rem',
    },
    '@media(max-device-width : 1180px)  and (orientation : landscape)': {
      width: '2.375rem',
      height: '2.375rem',
      minWidth: 'auto',
      borderRadius: '0.5rem',
      right: '5vh',
    },
    '& img': {
      height: '2.5rem',
      width: 'auto',
      '@media (max-width:600px)': {
        height: '1.5rem',
      },
      '@media(orientation: portrait) and (min-width: 600px)': {
        height: '2rem',
      },
    },
  },
});

const useStyles = makeStyles(styles);

const UpButton = () => {
  const classes = useStyles();

  const scrollToTop = useCallback(
    () => {
      document.documentElement.scroll({
        top: 0,
        behavior: 'smooth',
        transitionDuration: '2s',
      });
    },
    [],
  );
  return (
    <Button onClick={() => scrollToTop()} className={classes.button} id="scrollToTopBtn">
      <img src={UpArrowButton} alt="up button" />
    </Button>
  );
};

export default UpButton;

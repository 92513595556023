import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { CssBaseline, MuiThemeProvider } from '@material-ui/core';
import smoothscroll from 'smoothscroll-polyfill';
import theme from './theme';
import ChangeInitialScale from './common/ChangeInitialScale';
import App from './App';

smoothscroll.polyfill();

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <ChangeInitialScale />
        <App />
      </MuiThemeProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root'),
);

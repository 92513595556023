import React, { useRef, useState } from 'react';
import { makeStyles, useMediaQuery } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import SliderItem from '../ImageAndTextSliderItem';
import Dots from '../dots/Dots';

const useStyles = makeStyles(() => ({
  root: {
    height: 'auto',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'relative',
    padding: '2.813rem 1vh',
    overflow: 'hidden',
    '@media (max-width:600px)': {
      padding: '0',
      height: 'auto',
    },
    '@media (min-width:600px) and (orientation: portrait)': {
      height: 'auto',
      padding: '0 1vh',
      paddingBottom: '2.813rem',
    },
    '@media (orientation: landscape)': {
      padding: '2.813rem 0',
      height: 'auto',
    },
    '@media (orientation:landscape) and (max-device-width:900px)': {
      padding: '1.5rem 0',
    },
  },
  testimonialsTitle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 'auto',
    marginBottom: '1rem',
    '& h2': {
      fontStyle: 'italic !important',
      fontWeight: '400 !important',
    },
    '@media (max-width:600px)': {
      width: '40%',
      textAlign: 'center',
      margin: '1rem 0',
    },
    '@media (min-width:600px) and (orientation: portrait)': {
      margin: '3.125rem 0',
    },
    '@media (orientation: landscape)': {
      width: 'auto',
      margin: '0',
      marginBottom: '1rem',
    },
    '@media (orientation:landscape) and (max-device-width:900px)': {
      marginTop: '0',
      marginBottom: '1rem',
    },
  },
  testimonialsSlider: {
    display: 'flex',
    flexBasis: '80%',
    flexWrap: 'nowrap',
    flexDirection: 'row',
    width: '100%',
    position: 'relative',
    zIndex: '3',
    transition: 'all 1s ease-in-out',
    transform: (props) => `translate3d(${props.translate}vw, 0, 0)`,
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '-ms-overflow-style': 'none',
    scrollbarWidth: 'none',
    '@media (max-width:600px)': {
      flexDirection: 'row',
      flexBasis: '70%',
    },
  },
}));

function ImageAndTextSlider({ items, title, settings }) {
  const sliderRef = useRef(null);
  const slideItemRef = useRef(null);
  const [active, setActive] = useState(0);
  let clientX;
  let clientY;
  const [translate, setTranslate] = useState(0);
  const classes = useStyles({ translate });
  const [activeChangedByArrows, setActiveChangedByArrowsOrDots] = useState(0);
  const portraitOrientation = useMediaQuery('@media (orientation: portrait) and (hover: none) and (pointer: coarse)');

  const arrowButtonHandler = (direction) => {
    const scrollBarWidth = window.innerWidth - document.documentElement.clientWidth;
    const width = slideItemRef.current.clientWidth
      - scrollBarWidth;
    const testimonialWidth = Math.floor(width
      * (100 / document.documentElement.clientWidth));
    if (direction === 'forward') {
      if (active < items.length - 1) {
        setActive(active + 1);
        const translateValue = translate - testimonialWidth;
        setTranslate(translateValue);
      }
    } else if (active > 0) {
      setActive(active - 1);
      const translateValue = translate + testimonialWidth;
      setTranslate(translateValue);
    }
  };

  const getTouchStartCoordinates = (e) => {
    if (portraitOrientation) {
      clientX = e.touches[0].clientX;
      clientY = e.touches[0].clientY;
    }
  };

  const getTouchEndCoordinates = (e) => {
    if (portraitOrientation) {
      const deltaX = e.changedTouches[0].clientX - clientX;
      const deltaY = e.changedTouches[0].clientY - clientY;
      console.log(deltaY);
      if ((deltaY >= -20) && (deltaY <= 20)) {
        if (deltaX < 0) {
          arrowButtonHandler('forward');
        } else {
          arrowButtonHandler('back');
        }
      }
    }
  };

  return (
    <div
      className={classes.root}
      onTouchStart={getTouchStartCoordinates}
      onTouchEnd={getTouchEndCoordinates}
    >
      {title && (
        <div className={classes.testimonialsTitle}>
          <Typography variant="h4" color="textSecondary">
            {title}
          </Typography>
        </div>
      )}
      <div
        className={classes.testimonialsSlider}
        ref={sliderRef}
        aria-hidden="true"
        role="button"
      >
        {items.map((item, index) => (
          <SliderItem
            /* eslint-disable-next-line react/no-array-index-key */
            key={`${index}-${item.title}`}
            setTranslate={setTranslate}
            logo={item.logo}
            no={index}
            active={active === index}
            client={item.title}
            description={item.description}
            author={item.author}
            settings={{
              logoHeightValue: settings.logoHeight,
              logoWidth: item?.logoWidth,
              logoWidthPortrait: item?.logoWidthPortrait,
              logoHasEffect: item?.logoHasEffect,
              authorWordBoldIndex: item?.authorWordBoldIndex,
              displayLine: settings.displayLine,
              textFontStyle: settings.textFontStyle,
            }}
            location={item.location}
            slideItemRef={slideItemRef}
          />
        ))}
      </div>
      <Dots
        setActive={(activeIndex) => {
          setActive(activeIndex);
          setActiveChangedByArrowsOrDots(activeChangedByArrows + 1);
        }}
        arrowButtonHandler={arrowButtonHandler}
        length={items.length}
        active={active}
      />
    </div>
  );
}

ImageAndTextSlider.propTypes = {
  title: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape({
    logo: PropTypes.node,
    title: PropTypes.string,
    description: PropTypes.string,
    author: PropTypes.string,
    location: PropTypes.string,
  })),
  settings: PropTypes.shape({
    logoHeight: PropTypes.string,
    displayLine: PropTypes.bool,
    textFontStyle: PropTypes.string,
  }),
};

ImageAndTextSlider.defaultProps = {
  title: null,
  items: [
    {
      logo: null,
      title: null,
      description: null,
      author: null,
      location: null,
    },
  ],
  settings: {
    logoHeight: '100%',
    displayLine: true,
    textFontStyle: 'normal',
  },
};

export default ImageAndTextSlider;

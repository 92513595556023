import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import propTypes from 'prop-types';
import { blackRock, white, stratos } from '../../colors/index';
import AppButton from '../../common/button/AppButton';

const useStyle = makeStyles(() => ({
  wrapper: {
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'left 45%',
    width: '100%',
    position: 'relative',
    '@media (max-width:600px)': {
      backgroundImage: (props) => ` url(${props.images.mobile})`,
      height: '18.188rem',
    },
    '@media (min-width:600px) and (orientation: portrait)': {
      backgroundImage: (props) => ` url(${props.images.tablet})`,
      height: '25.063rem',
    },
    '@media (orientation: landscape)': {
      backgroundImage: (props) => `url(${props.images.web})`,
      height: '30.563rem',
    },
    '@media (orientation: landscape) and (max-device-width:900px)': {
      height: '20rem',
    },
    '& a': {
      textDecoration: 'none',
    },
    '&::before': {
      top: '0',
      left: '0',
      content: '""',
      pointerEvents: 'none',
      width: '100%',
      height: '100%',
      position: 'absolute',
      backgroundColor: 'rgba(255, 255, 255, .4)',
      '@supports ((-webkit-backdrop-filter: blur(10px)) or (backdrop-filter: blur(10px)))': {
        backgroundColor: 'rgba(255, 255, 255, .05)',
        webkitBackdropFilter: 'blur(10px)',
        backdropFilter: 'blur(10px)',
      },
    },
  },
  contentWrapper: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    height: 'inherit',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    padding: '3% 26% 0 26%',
    textAlign: 'center',
    '@media (orientation:landscape) and (min-device-width:1200px) and (min-width:600px) and (max-width:1300px)': {
      padding: '4% 15% 0 15%',
    },
    '@media (orientation: landscape) and (max-device-width:900px)': {
      padding: '4% 15% 0 15%',
    },
    '@media (max-width:600px)': {
      padding: '4% 12.5% 0 12.5%',
    },
    '@media (min-width:600px) and (orientation: portrait)': {
      padding: '4% 10% 0 10%',
    },
    '@media (orientation: landscape)': {
      padding: '3% 26% 0 26%',
    },
    '@media(max-width : 1180px)  and (orientation : landscape)': {
      padding: '4% 10% 0 10%',
    },
    '& a': {
      '@media (max-width:600px)': {
        marginTop: '0',
      },
      '@media (min-width:600px) and (orientation: portrait)': {
        marginTop: '1rem',
      },
    },
  },
  title: {
    fontWeight: '600',
    color: stratos,
  },
}));
const BusinessGrowth = ({
  title, description, paragraph, buttonText, images,
}) => {
  const classes = useStyle({ images });
  return (
    <div className={classes.wrapper}>

      <div className={classes.contentWrapper}>
        <Typography variant="h3" className={classes.title}>
          {title}
        </Typography>
        <Typography className={classes.description}>
          {description}
          {' '}
          <br />
          {' '}
          {paragraph}
        </Typography>
        <a href="https://www.neusoft.com" target="_blank" rel="noreferrer">
          <AppButton
            background="transparent"
            backgroundOnHover={blackRock}
            color={blackRock}
            colorOnHover={white}
            border={`2px solid ${blackRock}`}
            borderHover={`2px solid ${blackRock}`}
            paddingMobile="0.563rem 1.313rem"
            paddingTablet="0.813rem 1.563rem"
            padding="0.813rem 1.563rem"
          >
            <Typography variant="button">{buttonText}</Typography>
          </AppButton>
        </a>
        <div />
      </div>
    </div>
  );
};

BusinessGrowth.propTypes = {
  title: propTypes.string.isRequired,
  description: propTypes.string.isRequired,
  paragraph: propTypes.string.isRequired,
  buttonText: propTypes.string.isRequired,
  images: propTypes.shape({
    mobile: propTypes.string,
    tablet: propTypes.string,
    web: propTypes.string,
  }).isRequired,
};

export default BusinessGrowth;

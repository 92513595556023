import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useMediaQuery } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import { alto, white, stratos } from '../../colors';

const useStyles = makeStyles(() => ({
  container: {
    height: '100%',
    flex: 'none',
    display: 'grid',
    position: 'relative',
    transition: '900ms ease 0ms',
    zIndex: '3',
    width: '100vw',
    '@media (max-width:600px)': {
      padding: '0',
      display: 'flex',
      height: 'auto',
      flexDirection: 'column',
      marginBottom: '0',
      marginLeft: '0',
    },
    '@media (min-width:600px) and (orientation: portrait)': {
      paddingLeft: '4.42%',
      paddingRight: '5.46%',
      height: 'auto',
      marginLeft: '0',
      display: 'grid',
      gridTemplateColumns: '16% 40% auto 25%',
      gridTemplateRows: '24.8vw 8vw 38.8vw',
      marginBottom: '3.688rem',
    },
    '@media (orientation: landscape)': {
      justifyContent: 'center',
      gridTemplateColumns: '33% 5% 33%',
      gridTemplateRows: '5vw 5vw 15.1vw 8.6vw',
    },
    '@media (orientation:landscape) and (max-width:1600px)': {
      gridTemplateColumns: '41% 5% 41%',
      gridTemplateRows: '5vw 10vw 18vw 8.6vw',
    },
    '@media (orientation:landscape) and (max-width:1180px)': {
      height: 'auto',
      marginLeft: '0',
      display: 'grid',
      padding: '0 1vh',
      gridTemplateColumns: '33% 5% 33%',
      gridTemplateRows: '24.8vw 3vw 34vw',
      marginBottom: '1.5rem',
    },
    '@media (orientation:landscape) and (max-device-width:1180px)': {
      gridTemplateColumns: '40% 5% 40%',
    },
    '@media(max-device-width:900px)  and (orientation : landscape)': {
      gridTemplateColumns: '45% 5% 45%',
      gridTemplateRows: '5vw 10vw 40vh 8.6vw',
      marginBottom: '0',
    },
  },
  logoWrapper: {
    display: 'flex',
    background: white,
    position: 'relative',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 2,
    width: 'auto',
    height: 'auto',
    '@media (max-width:600px)': {
      width: '100vw',
      position: 'initial',
      marginTop: '0',
      height: '8.625rem',
    },
    '@media (min-width:600px) and (orientation: portrait)': {
      gridColumn: '1/4',
      gridRow: '1/3',
      marginTop: '0',
      zIndex: 3,
      alignSelf: 'initial',
    },
    '@media (orientation: landscape)': {
      gridColumn: '1/3',
      gridRow: '2/4',
      zIndex: 2,
    },
    '@media (orientation:landscape) and (max-width:1180px)': {
      gridColumn: '1/4',
      gridRow: '1/3',
      marginTop: '0',
      zIndex: 3,
      alignSelf: 'initial',
    },
    '@media (orientation:landscape) and (max-device-width:1180px)': {
      gridColumn: '1/3',
      gridRow: '2/4',
      zIndex: 2,
    },
  },
  descriptionWrapper: {
    display: 'flex',
    background: alto,
    right: 0,
    position: 'relative',
    flexDirection: 'column',
    justifyContent: 'center',
    '@media (max-width:600px)': {
      width: '100vw',
      height: '21.438rem',
      position: 'initial',
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
    },
    '@media (min-width:600px) and (orientation: portrait)': {
      gridColumn: '2/5',
      gridRow: '2/4',
      width: 'auto',
      height: 'auto',
      zIndex: 2,
      alignSelf: 'initial',
      top: '0',
    },
    '@media (orientation: landscape)': {
      gridColumn: '2/4',
      gridRow: '3/5',
      zIndex: 1,
    },
    '@media (orientation:landscape) and (max-width:1180px)': {
      gridColumn: '2/5',
      gridRow: '2/4',
      width: 'auto',
      height: 'auto',
      zIndex: 2,
      alignSelf: 'initial',
      top: '0',
    },
    '@media (orientation:landscape) and (max-device-width:1180px)': {
      gridColumn: '2/4',
      gridRow: '3/5',
      zIndex: 1,
    },
  },
  logo: {
    width: '100%',
    transform: (props) => (props.settings.logoHasEffect ? 'translate3d(-5vw, 0, 0)' : 'none'),
    transition: (props) => (props.settings.logoHasEffect ? '1200ms ease 900ms' : 'none'),
    overflow: 'hidden',
    height: (props) => props.settings.logoHeightValue,
    objectFit: 'cover',
  },
  logoContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: (props) => props.settings.logoWidth,
    '@media (max-width:600px)': {
      height: '8.625rem',
      width: (props) => props.settings.logoWidthPortrait,
    },
    '@media (min-width:600px) and (orientation: portrait)': {
      width: (props) => props.settings.logoWidthPortrait,
      height: '100%',
    },
    '@media (orientation: landscape)': {
      width: (props) => props.settings.logoWidth,
      height: '100%',
    },
    '@media (orientation:landscape) and (max-width:1180px)': {
      width: (props) => props.settings.logoWidthPortrait,
      height: '100%',
    },
  },
  client: {
    overflow: 'hidden',
    gridColumn: '2/4',
    gridRow: '1/3',
    display: 'flex',
    justifyContent: 'flex-start',
    zIndex: '4',
    alignItems: 'flex-end',
    '& h3': {
      width: (props) => props.settings.titleWidth,
      transform: 'translate(-100%, 0)',
      transition: '900ms ease 300ms',
      fontWeight: 'bold',
      '@media (max-width:600px)': {
        marginBottom: '1rem',
      },
      '@media (min-width:600px) and (orientation: portrait)': {
        zIndex: '4',
        textAlign: 'right',
        marginBottom: '1.5rem',
        marginRight: '2rem',
        paddingLeft: '50%',
      },
      '@media (orientation: landscape)': {
        marginBottom: '0',
        paddingBottom: '0.6rem',
        paddingLeft: '0',
        marginRight: '0',
        textAlign: 'left',
      },
      '@media (orientation:landscape) and (max-width:1180px)': {
        zIndex: '4',
        textAlign: 'right',
        marginBottom: '1.5rem',
        marginRight: '2rem',
        paddingLeft: '50%',
      },
      '@media (orientation:landscape) and (max-device-width:1180px)': {
        marginBottom: '0',
        paddingBottom: '0.6rem',
        paddingLeft: '0',
        marginRight: '0',
        textAlign: 'left',
      },
    },
    '@media (max-width:600px)': {
      position: 'initial',
      marginLeft: '10%',
      marginTop: '2.563rem',
    },
    '@media (min-width:600px) and (orientation: portrait)': {
      justifyContent: 'flex-end',
      gridColumn: '2/5',
    },
    '@media (orientation: landscape)': {
      justifyContent: 'flex-start',
      gridColumn: '2/4',
    },
    '@media (orientation:landscape) and (max-width:1180px)': {
      justifyContent: 'flex-end',
      gridColumn: '2/5',
    },
    '@media (orientation:landscape) and (max-device-width:1180px)': {
      justifyContent: 'flex-start',
      gridColumn: '2/4',
    },
  },
  active: {
    '& h3': {
      transform: 'translate(0%, 0) !important',
      transition: '900ms ease 300ms',
    },
    '& img': {
      transform: 'translate3d(0, 0, 0) !important',
      transition: '1200ms ease 900ms',
    },
  },
  alignItemsCenter: {
    alignItems: 'center',
  },
  text: {
    display: 'flex',
    zIndex: 1,
    paddingLeft: '16%',
    textAlign: 'left',
    width: '95%',
    fontStyle: (props) => props.settings.textFontStyle,
    '& p': {
      marginBottom: '2rem',
      fontStyle: 'italic',
    },
    '@media (orientation:landscape) and (max-width:1500px)': {
      width: '95%',
      paddingLeft: '15%',
      '& p': {
        marginBottom: '0.5rem',
      },
    },
    '@media (max-width:600px)': {
      position: 'initial',
      width: '90%',
      paddingLeft: '10%',
      '& p': {
        marginBottom: '1rem',
        marginTop: '0',
      },
    },
    '@media (min-width:600px) and (orientation: portrait)': {
      top: '20%',
      width: '90%',
      paddingLeft: '10%',
      '& p': {
        marginBottom: '1rem',
        marginTop: '0',
      },
    },
    '@media (orientation:landscape) and (max-device-width:900px)': {
      width: '97%',
      paddingLeft: '13.5%',
      '& p': {
        marginBottom: '0.5rem',
      },
    },
    '& span': {
      fontWeight: 'bold',
    },
  },
  location: { marginLeft: '1rem' },
  line: {
    width: '0.3rem',
    height: '0.1rem',
    backgroundColor: stratos,
    marginRight: '0.5rem',
    marginLeft: '0.1rem',
  },
  authorBox: {
    '@media (orientation: portrait) and (max-width:600px)': {
      marginBottom: '2rem',
    },

    '& *': {
      fontStyle: (props) => `${props.settings.textFontStyle} !important`,
    },
  },
  flexDisplayColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  flexDisplayRow: {
    display: 'flex',
    alignItems: 'center',
  },
  descriptionBox: {
    '@media (max-width:600px)': {
      marginTop: '0',
    },
    '@media (min-width:600px) and (orientation: portrait)': {
      marginTop: '3rem',
    },
    '@media (orientation: landscape)': {
      marginTop: 'initial',
    },
    '@media (orientation:landscape) and (max-device-width:900px)': {
      marginTop: '1rem',
    },
  },
  fontWeightNormal: {
    fontWeight: 'normal !important',
  },
}));

function ImageAndTextSliderItem({
  logo, client, description, author, location, active,
  settings, slideItemRef,
}) {
  const classes = useStyles({
    settings,
  });
  const mobileView = useMediaQuery('@media (orientation: portrait) and (max-width:600px)');

  const getAuthor = () => {
    if (settings?.authorWordBoldIndex || settings?.authorWordBoldIndex === 0) {
      const words = author?.split(' ');
      const sentence = words.map((word, index) => (
        <div
          /* eslint-disable-next-line react/no-array-index-key */
          key={`author-key-${index}`}
        >
          <Typography
            variant="body1"
            component="span"
            className={clsx(index !== settings.authorWordBoldIndex && classes.fontWeightNormal)}
          >
            {word}
          </Typography>
          <Typography
            variant="body1"
            component="span"
          >
            &nbsp;
          </Typography>
        </div>
      ));
      return sentence;
    }

    return (
      <Typography
        variant="body1"
        component="span"
      >
        {author}
      </Typography>
    );
  };
  return (
    <div
      className={classes.container}
      ref={slideItemRef}
    >
      <div className={`${classes.logoWrapper} ${active ? classes.active : ''}`}>
        <div className={classes.logoContainer}>
          <img
            className={classes.logo}
            src={logo}
            alt={client}
          />
        </div>
      </div>
      {!mobileView
        && <div className={`${classes.client} ${active ? classes.active : ''}`}><Typography variant="h3">{client}</Typography></div>}
      <div className={classes.descriptionWrapper}>
        {mobileView
          && <div className={`${classes.client} ${active ? classes.active : ''}`}><Typography variant="h3">{client}</Typography></div>}
        <div className={`${classes.text} ${classes.descriptionBox}`}><Typography variant="body1">{description}</Typography></div>
        <div className={clsx(classes.text, classes.authorBox, classes.alignItemsCenter)}>
          <div className={classes.flexDisplayColumn}>
            {author && (
              <div className={classes.flexDisplayRow}>
                {settings.displayLine && <div className={classes.line} />}
                {' '}
                {getAuthor()}
              </div>
            )}
            <Typography className={classes.location} variant="body1" component="span">{location}</Typography>
          </div>
        </div>
      </div>
    </div>
  );
}

ImageAndTextSliderItem.propTypes = {
  logo: PropTypes.node.isRequired,
  client: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  author: PropTypes.string.isRequired,
  location: PropTypes.string,
  active: PropTypes.bool.isRequired,
  settings: PropTypes.shape({
    logoWidth: PropTypes.string,
    logoWidthPortrait: PropTypes.string,
    logoHeightValue: PropTypes.string,
    titleWidth: PropTypes.string,
    titleTabletWidth: PropTypes.string,
    translate: PropTypes.number,
    logoHasEffect: PropTypes.bool,
    authorWordBoldIndex: PropTypes.number,
    displayLine: PropTypes.bool,
    textFontStyle: PropTypes.string,
  }),
  slideItemRef: PropTypes.shape({}),
};
ImageAndTextSliderItem.defaultProps = {
  location: null,
  settings: {
    logoWidth: '50%',
    logoWidthPortrait: '50%',
    logoHeightValue: 'auto',
    titleWidth: '50%',
    titleTabletWidth: 'auto',
    translate: 0,
    logoHasEffect: true,
    authorWordBoldIndex: null,
    displayLine: true,
    textFontStyle: 'normal',
  },
  slideItemRef: {},
};

export default ImageAndTextSliderItem;

import React, { useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import MissionCard from './MissionCard';
import innovate from '../../assets/images/innovate.png';
import accelerate from '../../assets/images/accelerate.png';
import growth from '../../assets/images/growth.png';
import mature from '../../assets/images/mature.png';
import accelerateMobile from '../../assets/images/accelerate-mobile.png';
import innovateMobile from '../../assets/images/innovate-mobile.png';
import growthMobile from '../../assets/images/growth-mobile.png';
import matureMobile from '../../assets/images/mature-mobile.png';

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    position: 'relative',
    overflow: 'hidden',
    '@media (orientation: portrait)': {
      cursor: 'grab',
      width: 'auto',
      overflow: 'scroll',
      flexBasis: '80%',
      '-ms-overflow-style': 'none',
      scrollbarWidth: 'none',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
      '@media (max-width:600px)': {
        height: '24.438rem',
      },
      '@media (min-width:600px) and (orientation: portrait)': {
        height: '27.438rem',
      },
    },
    '@media (orientation: landscape)': {
      height: '32vw',
      '@media(max-width: 1366px)': {
        height: '35vw',
      },
      '@media(max-width: 1080px)': {
        height: '45vw',
        cursor: 'grab',
        width: 'auto',
        overflow: 'scroll',
        flexBasis: '80%',
        '-ms-overflow-style': 'none',
        scrollbarWidth: 'none',
        '&::-webkit-scrollbar': {
          display: 'none',
        },
      },
      '@media(max-width: 800px)': {
        height: '50vw',
      },
      '@media(max-device-width: 900px)': {
        cursor: 'grab',
        width: 'auto',
        overflow: 'scroll',
        flexBasis: '80%',
        '-ms-overflow-style': 'none',
        scrollbarWidth: 'none',
        '&::-webkit-scrollbar': {
          display: 'none',
        },
      },
    },
  },
}));

const Missions = () => {
  const classes = useStyles();
  const sliderRef = useRef(null);
  const [position, setPosition] = useState({
    isScrolling: false,
    left: 0,
    x: 0,
  });
  const mouseMoveHandler = (e) => {
    if (position.isScrolling) {
      const dx = e.clientX - position.x;
      sliderRef.current.scrollLeft = position.left - dx;
    }
  };
  const mouseUpHandler = () => {
    sliderRef.current.style.cursor = 'grab';
    sliderRef.current.style.removeProperty('user-select');
    setPosition({
      ...position,
      isScrolling: false,
    });
  };
  const mouseDownHandler = (e) => {
    sliderRef.current.style.cursor = 'grabbing';
    sliderRef.current.style.userSelect = 'none';
    setPosition({
      ...position,
      isScrolling: true,
      left: sliderRef.current.scrollLeft,
      x: e.clientX,
    });
  };
  return (
    <div
      className={classes.wrapper}
      ref={sliderRef}
      onMouseDown={mouseDownHandler}
      onMouseUp={mouseUpHandler}
      onMouseMove={mouseMoveHandler}
      aria-hidden="true"
      role="button"
    >
      <MissionCard
        title="Innovate"
        subtitle="for competitive products"
        description="With outstanding ability in custom software engineering and multi-industry experience, we help our clients optimize their own product innovation and customer experience, to further enhance competitiveness."
        image={innovate}
        mobileImage={innovateMobile}
        alt="innovate"
      />
      <MissionCard
        title="Accelerate"
        subtitle="digital transformation"
        description="With emerging technologies, such as IoT, wearables, intelligent human machine interaction, automated processes, virtual & augmented reality we help our clients increase digital transformation and maintain their competitive edge."
        image={accelerate}
        mobileImage={accelerateMobile}
        alt="accelerate"
      />
      <MissionCard
        title="Growth"
        subtitle="by open innovation"
        description="We are open to sharing and receiving input. An environment where people can propose ideas to fix a problem. We believe in a transparent utilization of all contributors to achieve a greater good."
        image={growth}
        mobileImage={growthMobile}
        alt="growth"
      />
      <MissionCard
        title="Mature"
        subtitle="delivery experience"
        description="Two-decade experience serving top businesses. Our solutions gather competences in different technologies and offer a new perspective for working cross-platform."
        image={mature}
        mobileImage={matureMobile}
        alt="mature"
      />
    </div>
  );
};

export default Missions;

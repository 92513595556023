import React from 'react';
import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import DownArrow from '../../assets/images/icons8-down-arrow.gif';

const useStyles = makeStyles(() => ({
  downArrow: {
    cursor: 'pointer',
    width: '3.375rem',
    height: '3.375rem',
    alignSelf: 'center',
    bottom: 0,
    position: 'absolute',
    border: 'none',
    background: 'none',
    marginBottom: '2.5rem',
    '@media (max-width:600px)': {
      width: '5vh',
      height: '5vh',
      marginBottom: '2rem',
    },
    '@media (max-device-width:600px)': {
      width: '6vh',
      height: '6vh',
      marginBottom: '2rem',
    },
    '@media (min-width:600px) and (orientation: portrait)': {
      marginBottom: '1.625rem',
      width: '6vh',
      height: '6vh',
    },
    '@media (orientation: landscape) and (max-device-width:900px)': {
      width: '10vh',
      height: '10vh',
      marginBottom: '1rem',
    },
    '& img': {
      width: '100%',
    },
  },
}));

const ScrollDownArrow = ({ navbarRef }) => {
  const classes = useStyles();

  const scrollDownBtn = () => {
    const headerElement = window.getComputedStyle(navbarRef.current);
    const headerHeight = headerElement.height.split('px')[0];
    const scrollHeight = window.innerHeight - parseInt(headerHeight, 10) - window.scrollY;
    const htmlElement = document.documentElement;
    htmlElement.style.scrollBehavior = 'smooth';
    window.scrollBy(0, scrollHeight);
  };
  return (
    <button
      className={classes.downArrow}
      onClick={scrollDownBtn}
      type="button"
      tabIndex="0"
    >
      <img src={DownArrow} alt="Press" />
    </button>
  );
};
ScrollDownArrow.propTypes = {
  navbarRef: PropTypes.shape({ current: PropTypes.shape({}) }),
};

ScrollDownArrow.defaultProps = {
  navbarRef: null,
};

export default ScrollDownArrow;

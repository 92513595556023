import { createTheme } from '@material-ui/core/styles';
import {
  alabaster, blackRock, malibu, salmon, stratos, endeavour,
} from '../colors';

const defaultTheme = createTheme();

const theme = createTheme({
  breakpoints: {
    values: {
      xxs: 0, // small phone
      xs: 300, // phone
      sm: 600, // tablets
      md: 900, // small laptop
      lg: 1200, // desktop
      xl: 1536, // large screens
    },
  },
  palette: {
    primary: { main: alabaster },
    secondary: { main: malibu },
    text: {
      primary: blackRock,
      secondary: salmon,
    },
    logo: {
      primary: endeavour,
    },
  },
  typography: {
    // #neusoft is me
    h1: {
      color: blackRock,
      fontFamily: 'neo-sans, sans-serif',
      fontStyle: 'normal',
      letterSpacing: 0,
      lineHeight: '9.063rem', // 145px
      fontWeight: 'normal',
      display: 'inline-block',
      [defaultTheme.breakpoints.between('xs', 'sm')]: {
        lineHeight: '2.625rem',
        fontSize: '2.25rem',
      },
      [defaultTheme.breakpoints.between('sm', 'md')]: {
        lineHeight: 'clamp(2.625rem, 21.4286vw - 5.4107rem, 4.875rem)',
        fontSize: 'clamp(2.25rem, 19.0476vw - 4.8929rem, 4.25rem)',
        // minWidth = 600px and maxWidth = 768px for all between('sm', 'md') breakpoints
      },
      [defaultTheme.breakpoints.up('lg')]: {
        lineHeight: '9.063rem',
        fontSize: '7.875rem',
      },
      [defaultTheme.breakpoints.between('md', 'lg')]: {
        lineHeight: 'clamp(4.875rem, 6.9804vw + 0.6868rem, 9.063rem)',
        fontSize: 'clamp(4.25rem, 6.0419vw + 0.6248rem, 7.875rem)',
        // slope= (maxFontSize - minFontSize)/(maxWidth - minWidth)
        // =(7.875 -4.25)/(119.997 - 60) = 0.0604196877
        // yAxisIntersection = - minWidth * slope + minFontSize
        // = - 60 * 0.0604196877 + 4.25 = 0.624818738
        // preferredValue = yAxisIntersection[rem] + (slope*100)[vw] =0.6248rem + 6.0419vw
      },
    },
    // Smart fitness
    h2: {
      color: stratos,
      fontFamily: 'neo-sans, sans-serif',
      fontStyle: 'italic',
      letterSpacing: 0,
      lineHeight: '5.313rem', // 85px
      fontWeight: 'normal',
      fontSize: '4.375rem', // 70px
      [defaultTheme.breakpoints.between('xs', 'sm')]: {
        lineHeight: '1.875rem',
        fontSize: '1.5rem',
      },
      [defaultTheme.breakpoints.between('sm', 'md')]: {
        lineHeight: 'clamp(1.875rem, 8.9333vw - 1.4750rem, 2.813rem)',
        fontSize: 'clamp(1.5rem, 7.1429vw - 1.1786rem, 2.25rem)',
      },
      [defaultTheme.breakpoints.up('lg')]: {
        lineHeight: '5.313rem',
        fontSize: '4.375rem',
      },
      [defaultTheme.breakpoints.between('md', 'lg')]: {
        lineHeight: 'clamp(2.813rem, 4.1669vw + 0.3129rem, 5.313rem)',
        fontSize: 'clamp(2.25rem, 3.5419vw + 0.1249rem, 4.375rem)',
        // slope= (maxFontSize - minFontSize)/(maxWidth - minWidth)
        // =(4.375 -2.25)/(119.997 - 60) = 0.0354184376
        // yAxisIntersection = - minWidth * slope + minFontSize
        // preferredValue = yAxisIntersection[rem] + (slope*100)[vw] =0.1249rem + 3.5419vw
      },
    },
    h3: {
      // The professional and reliable partner
      color: stratos,
      fontFamily: 'neo-sans, sans-serif',
      fontStyle: 'normal',
      letterSpacing: 0,
      lineHeight: '4.375rem', // 70px
      fontWeight: 'bold',
      fontSize: '3.125rem', // 50px
      [defaultTheme.breakpoints.between('xs', 'sm')]: {
        lineHeight: '1.813em',
        fontSize: '1.25rem',
      },
      [defaultTheme.breakpoints.between('sm', 'md')]: {
        lineHeight: 'clamp(1.813rem, 9.5238vw - 1.17584rem, 2.813rem)',
        fontSize: 'clamp(1.25rem, 5.952vw - 0.9821rem, 1.875rem)',
      },
      [defaultTheme.breakpoints.up('lg')]: {
        lineHeight: '4.375rem',
        fontSize: '3.125rem',
      },
      [defaultTheme.breakpoints.between('md', 'lg')]: {
        lineHeight: 'clamp(2.813rem, 2.6035vw + 1.2509rem, 4.375rem)',
        fontSize: 'clamp(1.8751rem, 2.0834vw + 0.6249rem, 3.125rem)',
      },
    },
    h4: {
      // What's new
      color: stratos,
      fontFamily: 'neo-sans, sans-serif',
      fontStyle: 'italic',
      letterSpacing: 0,
      lineHeight: '4.375rem', // 70px
      fontWeight: 'normal',
      fontSize: '3.125rem', // 50px
      [defaultTheme.breakpoints.between('xs', 'sm')]: {
        lineHeight: '1.813em',
        fontSize: '1.25rem',
      },
      [defaultTheme.breakpoints.between('sm', 'md')]: {
        lineHeight: 'clamp(1.813rem, 5.3524vw - 0.1941rem, 2.375rem)',
        fontSize: 'clamp(1.25rem, 3.5714vw - 0.0893rem, 1.625rem)',
      },
      [defaultTheme.breakpoints.up('lg')]: {
        lineHeight: '4.375rem',
        fontSize: '3.125rem',
      },
      [defaultTheme.breakpoints.between('md', 'lg')]: {
        lineHeight: 'clamp(2.375rem, 3.3335vw + 0.3749rem, 4.375rem)',
        fontSize: 'clamp(1.625rem, 2.5001vw + 0.1249rem, 3.125rem)',
      },
    },
    h5: {
      // What's new
      color: stratos,
      fontFamily: 'neo-sans, sans-serif',
      fontStyle: 'normal',
      letterSpacing: 0,
      lineHeight: '3.125rem', // 50px
      fontWeight: 'bold',
      fontSize: '2.5rem', // 40px
      [defaultTheme.breakpoints.between('xs', 'sm')]: {
        lineHeight: '1.438rem',
        fontSize: '1.125rem',
      },
      [defaultTheme.breakpoints.between('sm', 'md')]: {
        lineHeight: 'clamp(1.438rem, 1.7810vw + 0.7701rem, 1.625rem)',
        fontSize: 'clamp(1.125rem, 1.1905vw + 0.6786rem, 1.25rem)',
      },
      [defaultTheme.breakpoints.up('lg')]: {
        lineHeight: '3.125rem',
        fontSize: '2.5rem',
      },
      [defaultTheme.breakpoints.between('md', 'lg')]: {
        lineHeight: 'clamp(1.625rem, 2.5001vw + 0.1249rem, 3.125rem)',
        fontSize: 'clamp(1.25rem, 2.0834vw - 0.0001rem, 2.5rem)',
      },
    },
    subtitle1: {
      // What's new
      color: stratos,
      fontFamily: 'neo-sans, sans-serif',
      fontStyle: 'normal',
      letterSpacing: 0,
      lineHeight: '2.5rem', // 40px
      fontWeight: 'normal',
      fontSize: '1.875rem', // 30px
      [defaultTheme.breakpoints.between('xs', 'sm')]: {
        lineHeight: '1rem',
        fontSize: '0.875rem',
      },
      [defaultTheme.breakpoints.between('sm', 'md')]: {
        lineHeight: 'clamp(1rem, 2.9810vw - 0.1179rem, 1.313rem)',
        fontSize: 'clamp(0.875rem, 2.3810vw - 0.0179rem, 1.125rem)',
      },
      [defaultTheme.breakpoints.up('lg')]: {
        lineHeight: '2.5rem',
        fontSize: '1.875rem',
      },
      [defaultTheme.breakpoints.between('md', 'lg')]: {
        lineHeight: 'clamp(1.313rem, 1.9784vw + 0.1259rem, 2.5rem)',
        fontSize: 'clamp(1.125rem, 1.2501vw + 0.3750rem, 1.875rem)',
      },
    },
    subtitle2: {
      // What's new
      color: stratos,
      fontFamily: 'neo-sans, sans-serif',
      fontStyle: 'normal',
      letterSpacing: 0,
      lineHeight: '2.5rem', // 40px
      fontWeight: 'bold',
      fontSize: '1.875rem', // 30px
      [defaultTheme.breakpoints.between('xs', 'sm')]: {
        lineHeight: '1rem',
        fontSize: '0.875rem',
      },
      [defaultTheme.breakpoints.between('sm', 'md')]: {
        lineHeight: 'clamp(1rem, 2.9810vw - 0.1179rem, 1.313rem)',
        fontSize: 'clamp(0.875rem, 2.3810vw - 0.0179rem, 1.125rem)',
      },
      [defaultTheme.breakpoints.up('lg')]: {
        lineHeight: '2.5rem',
        fontSize: '1.875rem',
      },
      [defaultTheme.breakpoints.between('md', 'lg')]: {
        lineHeight: 'clamp(1.313rem, 1.9784vw + 0.1259rem, 2.5rem)',
        fontSize: 'clamp(1.125rem, 1.2501vw + 0.3750rem, 1.875rem)',
      },
    },
    body1: {
      color: stratos,
      fontFamily: 'neo-sans, sans-serif',
      fontStyle: 'normal',
      letterSpacing: 0,
      lineHeight: '1.75rem', // 28px
      fontWeight: 'normal',
      fontSize: '1.25rem', // 20px
      [defaultTheme.breakpoints.between('xs', 'sm')]: {
        lineHeight: '1rem',
        fontSize: '0.75rem',
      },
      [defaultTheme.breakpoints.between('sm', 'md')]: {
        lineHeight: 'clamp(1rem, 2.9810vw - 0.1179rem, 1.313rem)',
        fontSize: 'clamp(0.75rem, 2.3810vw - 0.1429rem, 1rem)',
      },
      [defaultTheme.breakpoints.up('lg')]: {
        lineHeight: '1.75rem',
        fontSize: '1.25rem',
      },
      [defaultTheme.breakpoints.between('md', 'lg')]: {
        lineHeight: 'clamp(1.313rem, 0.7284vw + 0.8760rem, 1.75rem)',
        fontSize: 'clamp(1rem, 0.4167vw + 0.7500rem, 1.25rem)',
      },
    },
    button: {
      fontFamily: 'neo-sans, sans-serif',
      fontStyle: 'normal',
      letterSpacing: 0,
      lineHeight: '1.375rem', // 22px
      fontWeight: 'bold',
      fontSize: '1rem', // 16px
      [defaultTheme.breakpoints.between('xs', 'sm')]: {
        lineHeight: '1rem',
        fontSize: '0.75rem',
      },
      [defaultTheme.breakpoints.between('sm', 'md')]: {
        lineHeight: 'clamp(1rem, 3.5714vw - 0.3393rem, 1.375rem)',
        fontSize: 'clamp(0.75rem, 2.3810vw - 0.1429rem, 1rem)',
      },
      [defaultTheme.breakpoints.up('md')]: {
        lineHeight: '1.375rem',
        fontSize: '1rem',
      },
    },
    caption: {
      color: stratos,
      fontFamily: 'neo-sans, sans-serif',
      fontStyle: 'normal',
      letterSpacing: 0,
      fontWeight: 'normal',
      [defaultTheme.breakpoints.between('xs', 'sm')]: {
        lineHeight: '0.875rem',
        fontSize: '0.625rem',
      },
      [defaultTheme.breakpoints.between('sm', 'md')]: {
        lineHeight: 'clamp(0.875rem, 3.5714vw - 0.4643rem, 1.25rem)',
        fontSize: 'clamp(0.625rem, 2.3810vw - 0.2679rem, 0.875rem)',
      },
      [defaultTheme.breakpoints.up('md')]: {
        lineHeight: '1.25rem',
        fontSize: '0.875rem',
      },
    },
  },
});

export default theme;

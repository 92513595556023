import React, { useEffect, useRef, useState } from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

const useStyle = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: (props) => `${props.settings.backgroundPosition} center`,
    justifyContent: 'space-around',
    position: 'relative',
    overflow: 'hidden',
    '@media (orientation: portrait) and (max-width:600px)': {
      backgroundImage: (props) => `url(${props.images.mobile})`,
      height: '16.125rem',
    },
    '@media (min-width:600px) and (orientation: portrait)': {
      backgroundImage: (props) => `url(${props.images.tablet})`,
      height: '18.75rem',
    },
    '@media (orientation: landscape)': {
      backgroundImage: (props) => `url(${props.images.web})`,
      height: '25rem',
    },
    '@media (min-width:2200px)': {
      height: '30rem',
    },
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    '@media (orientation: portrait)': {
      flexDirection: 'column',
      width: (props) => props.settings.rowWidthTablet,
      justifyContent: 'flex-start',
    },
    '@media (orientation: landscape)': {
      flexDirection: 'row',
      width: (props) => props.settings.rowWidth,
      justifyContent: 'center',
    },
  },
  backgroundEffect: {
    left: 0,
    position: 'absolute',
    height: '100%',
    width: '100%',
    background: (props) => `linear-gradient(102deg, rgba(0, 91, 172,${props.settings.backgroundOpacity}),rgba(0, 204, 255,${props.settings.backgroundOpacity})) 100% 100% no-repeat`,
    mixBlendMode: 'multiply',
    '@media (orientation: portrait)': {
      background: (props) => `rgba(0, 0, 0,${props.settings.backgroundOpacityPortrait}) 100% 100% no-repeat`,
      mixBlendMode: 'darken',
    },
  },
  title: {
    textAlign: 'center',
    fontWeight: '600',
  },
  subtitle: {
    color: 'white',
    textAlign: 'center',
  },
  '@keyframes moveToTop': {
    '0%': {
      transform: 'translateY(70vh)',
    },
    '100%': {
      transform: 'translateY(0)',
    },
  },
  '@keyframes fadeInMoveToTop': {
    '0%': {
      opacity: 0,
      transform: 'translateY(100vh)',
    },
    '80%': {
      opacity: 0.3,
    },
    '100%': {
      opacity: 1,
      transform: 'translateY(0)',
    },
  },
  animateTitleToTop: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    transform: 'translateY(70vh)',
    animationName: '$moveToTop',
    animationDuration: '1.1s',
    animationIterationCount: '1',
    animationTimingFunction: 'ease-in-out',
    animationFillMode: 'forwards',
    width: '100%',
    height: '100%',
    zIndex: '1',
    position: 'relative',
    '& h3': {
      padding: (props) => props.settings.padding,
      marginBottom: '2%',
      color: 'white !important',
      '@media (orientation: portrait) and (max-width:600px)': {
        padding: (props) => props.settings.paddingMobile,
      },
      '@media (orientation: landscape) and (max-width:900px)': {
        padding: (props) => props.settings.paddingMobileLandscape,
      },
    },
  },
  animateImageAndDescription: {
    transform: 'translateY(70vh)',
    animationName: '$fadeInMoveToTop',
    animationDuration: '600ms',
    animationDelay: '0.3s',
    animationIterationCount: '800ms',
    animationTimingFunction: 'ease-in-out',
    animationFillMode: 'forwards',
  },
  hideElement: {
    visibility: 'hidden',
    transform: 'translateY(100vh)',
  },
}));

const CompanyDescription = ({
  title, description, images, settings,
}) => {
  const ref = useRef(null);
  const [isInView, setIsInView] = useState(false);
  const classes = useStyle({ images, settings });

  useEffect(() => {
    const visibilityObserver = new IntersectionObserver((entry) => {
      if (entry[0].isIntersecting) {
        setIsInView(true);
        visibilityObserver.disconnect();
      }
    }, {
      threshold: 0.1,
    });

    visibilityObserver.observe(ref.current);
  }, []);

  return (
    <div ref={ref} className={classes.wrapper}>
      <div className={classes.row}>
        <div className={`${isInView ? classes.animateTitleToTop : classes.hideElement}`}>
          <Typography variant="h3" className={classes.title}>
            {title}
          </Typography>
          <Typography variant="body1" className={`${classes.subtitle} ${isInView ? classes.animateImageAndDescription : classes.hideElement}`}>
            {description}
          </Typography>
        </div>
        <div className={classes.backgroundEffect} />
      </div>
    </div>
  );
};

CompanyDescription.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  images: PropTypes.shape({
    mobile: PropTypes.string,
    tablet: PropTypes.string,
    web: PropTypes.string,
  }).isRequired,
  settings: PropTypes.shape({
    rowWidth: PropTypes.string,
    rowWidthTablet: PropTypes.string,
    padding: PropTypes.string,
    paddingMobile: PropTypes.string,
    paddingLandscapeMobile: PropTypes.string,
    backgroundPosition: PropTypes.string,
    backgroundOpacity: PropTypes.string,
    backgroundOpacityPortrait: PropTypes.string,
  }),

};

CompanyDescription.defaultProps = {
  settings: PropTypes.shape({
    rowWidth: '60%',
    rowWidthTablet: '80%',
    padding: '0',
    paddingMobile: '0',
    paddingLandscapeMobile: '0',
    backgroundPosition: 'right',
    backgroundOpacity: '0.25',
    backgroundOpacityPortrait: '0.4',
  }),
};

export default CompanyDescription;

import React, { useEffect, useRef, useState } from 'react';
import { makeStyles, Typography, useMediaQuery } from '@material-ui/core';
import PropTypes from 'prop-types';
import { malibu, stratos } from '../../colors';
import neusoftIsMeHero from '../../assets/images/neusoft-isme-hero.jpg';
import neusoftEDCHero from '../../assets/images/neusoft-edc-hero.jpg';
import neusoftTalentHero from '../../assets/images/neusoft-people-hero.jpg';
import neusoftSmartHero from '../../assets/images/neusoft-smart-hero.jpg';
import neusoftBusinessHero from '../../assets/images/neusoft-business-hero.jpg';
import neusoftWareHero from '../../assets/images/neusoft-ware-hero.jpg';
import ScrollDownArrow from '../ScrollDownArrow';

const useStyles = makeStyles(() => ({
  root: {
    width: '60%',
    margin: '0 auto',
    height: '100%',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '@media (max-width:600px)': {
      flexDirection: 'column',
      width: '80%',
    },
    '@media (min-width:600px) and (orientation: portrait)': {
      width: '80%',
    },
    '@media(max-device-width : 1180px)  and (orientation : landscape)': {
      width: '80%',
    },
    '@media(max-width : 1180px)  and (orientation : landscape)': {
      width: '80%',
    },
  },
  '@keyframes rotateText': {
    '0%': { top: '100%' },
    '3%': { top: '0%' },
    '16.5%': { top: '0%' },
    '19.5%': { top: '-100%' },
    '100%': { top: '-100%' },

  },
  '@keyframes rotateImage': {
    '0%': {
      opacity: 0,
    },
    '11.90%': {
      opacity: 1,
    },
    '20%': {
      opacity: 0,
    },
    '95.23%': {
      opacity: 0,
    },
    '100%': {
      opacity: 0,
    },
  },
  heroTextWrapper: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    zIndex: 1,
    top: '33.36%',
    left: 0,
    '@media (min-width:600px) and (orientation: portrait)': {
      top: '47%',
    },
    '@media (orientation: landscape) and (max-device-width:900px)': {
      top: '25%',
    },
    '@media (min-width:600px) and (orientation: portrait) and (min-device-width:1000px)': {
      top: '30%',
    },
    '@media (min-width:600px) and (max-width:1000px) and (min-device-width:1000px)': {
      top: '50%',
    },
    '@media (min-width:600px) and (max-width:800px) and (min-device-width:1000px)': {
      top: '47%',
    },
    '@media (max-width:600px)': {
      top: '54.57%',
    },
    '@media (max-width:600px) and (min-device-width:1000px)': {
      top: '58%',
    },
  },
  heroTextStatic: {
    '@media (orientation: portrait)': {
      margin: 'auto',
    },
  },
  heroTextTitleBox: {
    display: 'flex',
    width: '100%',
  },
  heroTextAnimationWrapper: {
    position: 'relative',
    overflow: 'hidden',
    height: 'auto',
    flexGrow: 1,
    '@media (orientation: portrait)': {
      height: 'auto',
    },
  },
  heroTextAnimationContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    position: 'relative',
    overflow: 'hidden',
    height: '100%',
    '@media (orientation: portrait)': {
      width: '100%',
    },
  },
  heroTextContentBox: {
    position: 'relative',
  },
  heroTextContainer: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    animationName: '$rotateImage',
    animationDuration: '42s',
    animationIterationCount: 'infinite',
    animationTimingFunction: 'ease-in-out',
    opacity: 0,
    marginTop: '4.6%',
    '@media (max-width:600px)': {
      width: '100%',
    },
    '@media (min-width:600px)': {
      width: '50%',
    },
    '@media (min-width:600px) and (orientation:portrait)': {
      width: '100%',
    },
    '@media (min-width:1000px) and (orientation:portrait)': {
      width: '50%',
    },
    '@media (min-width:600px) and (max-width:1000px) and (min-device-width:1000px)': {
      width: '100%',
    },
    '@media(max-device-width : 1180px)  and (orientation : landscape)': {
      width: '50%',
    },
    '& h4': {
      width: '93%',
      height: 'auto',
      fontWeight: 'bold',
      color: stratos,
      fontFamily: 'neo-sans, sans-serif',
      fontStyle: 'normal',
      letterSpacing: 0,
      lineHeight: '2.5rem',
      fontSize: '1.875rem',
      '& span': {
        fontStyle: 'italic',
      },
      '@media (max-width:600px)': {
        lineHeight: '1.438rem',
        fontSize: '1.125rem',
        width: '70%',
      },
      '@media (min-width:600px) and (orientation: portrait)': {
        width: '90%',
        lineHeight: 'clamp(1.438rem, 10.1143vw - 2.3549rem, 2.5rem)',
        fontSize: 'clamp(1.125rem, 7.1429vw - 1.5536rem, 1.875rem)',
      },
      '@media (max-width:600px) and (min-device-width:1000px)': {
        width: '65%',
      },
      '@media (orientation: landscape) and (max-device-width:900px)': {
        lineHeight: '1.438rem',
        fontSize: '1.125rem',
        width: '100%',
      },
    },
    '& p': {
      marginTop: '3.14%',
      width: '93%',
      '@media (max-width:600px)': {
        width: '70%',
      },
      '@media (min-width:600px) and (orientation: portrait)': {
        width: '90%',
      },
      '@media (max-width:600px) and (min-device-width:1000px)': {
        width: '65%',
      },
      '@media (orientation: landscape) and (max-device-width:900px)': {
        width: '100%',
      },
    },
  },
  heroTextSecondary: {
    color: malibu,
    fontStyle: 'italic',
    fontWeight: 'bold',
    position: 'absolute',
    top: '100%',
    animationName: '$rotateText',
    animationDuration: '42s',
    animationIterationCount: 'infinite',
    transition: 'all 1s ease-in-out',
  },
  heroImageWrapper: {
    position: 'relative',
    width: '100%',
    height: '100%',
  },
  container: {
    width: '47vmin',
    height: '47vmin',
    display: 'flex',
    position: 'absolute',
    right: 0,
    top: '31%',
    justifyContent: 'flex-end',
    borderRadius: '0px 50px 0px 0px',
    boxShadow: '0px 10px 15px #00002E',
    animationName: '$rotateImage',
    animationDuration: '42s',
    animationIterationCount: 'infinite',
    animationTimingFunction: 'ease-in-out',
    opacity: 0,
    overflow: 'hidden',
    '@media (orientation: landscape) and (max-width:1800px)': {
      top: '22%',
    },
    '@media (max-width:600px)': {
      top: '16.5%',
      width: '75%',
      height: 'auto',
      boxShadow: '0px 5px 10px #00002E',
      borderRadius: '0px 25px 0px 0px',
    },
    '@media (min-width:600px) and (orientation: portrait)': {
      top: '23.73%',
      width: '50.5%',
      height: 'auto',
    },
  },
  heroImage: {
    display: 'block',
    width: '100%',
    height: '100%',
    '@media (orientation: portrait)': {
      objectFit: 'cover',
    },
  },
  downArrow: {
    width: '3.375rem',
    height: '3.375rem',
    position: 'absolute',
    bottom: 0,
    marginBottom: '2.5rem',
    '@media (max-width:600px)': {
      width: '1.5rem',
      height: '1.5rem',
      marginBottom: '2rem',
    },
    '@media (min-width:600px) and (orientation: portrait)': {
      marginBottom: '1.625rem',
      width: '1.8rem',
      height: '1.8rem',
    },
    '@media (orientation: landscape) and (max-device-width:900px)': {
      width: '1.5rem',
      height: '1.5rem',
      marginBottom: '1rem',
    },
    '& img': {
      width: '100%',
    },
  },
  heroSubtitleBox: {
    opacity: '0.65',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '3%',
    '& span': {
      paddingBottom: '1.8%',
      '@media (max-width:600px)': {
        paddingBottom: '5%',
      },
      '& span': {
        fontWeight: 'bold',
      },
    },
  },
}));

function Hero({ navbarRef }) {
  const firstSectionRef = useRef(null);
  const classes = useStyles();
  const [isInView, setIsInView] = useState(false);

  useEffect(() => {
    const intersectionObserver = new IntersectionObserver((entry) => {
      if (entry[0].isIntersecting) {
        setIsInView(true);
      } else {
        setIsInView(false);
      }
    }, {
      threshold: 0.25,
    });
    const sectionToBeObserved = document.getElementsByTagName('section')[1];
    intersectionObserver.observe(sectionToBeObserved);
    return () => {
      if (sectionToBeObserved) {
        intersectionObserver.unobserve(sectionToBeObserved);
      }
    };
  }, []);

  const landscapeOrientation = useMediaQuery('@media (orientation: landscape)');

  return (
    <div className={classes.root} ref={firstSectionRef}>
      <div
        className={classes.heroTextWrapper}
        style={{
          transform: landscapeOrientation && isInView ? 'translate3d(0,70%,0)' : 'translate3d(0, 0%,0)',
          transition: '1000ms',
        }}
      >
        <div className={classes.heroTextTitleBox}>
          <Typography className={classes.heroTextStatic} variant="h1">
            #neusoft
          </Typography>
          <div className={classes.heroTextAnimationWrapper}>
            <div className={classes.heroTextAnimationContent} id="hero-animation">
              <Typography variant="h1" component="h2" className={classes.heroTextSecondary} style={{ animationDelay: '0s' }}>
                edc
              </Typography>
              <Typography variant="h1" component="h2" className={classes.heroTextSecondary} style={{ animationDelay: '7s' }}>
                isme
              </Typography>
              <Typography variant="h1" component="h2" className={classes.heroTextSecondary} style={{ animationDelay: '14s' }}>
                talent
              </Typography>
              <Typography variant="h1" component="h2" className={classes.heroTextSecondary} style={{ animationDelay: '21s' }}>
                smart
              </Typography>
              <Typography variant="h1" component="h2" className={classes.heroTextSecondary} style={{ animationDelay: '28s' }}>
                ware
              </Typography>
              <Typography variant="h1" component="h2" className={classes.heroTextSecondary} style={{ animationDelay: '35s' }}>
                business
              </Typography>
            </div>
          </div>
        </div>
        <div className={classes.heroTextContentBox}>
          <div className={classes.heroTextContainer} style={{ animationDelay: '0s' }}>
            <Typography component="h4">
              European Delivery Center. We deliver
            </Typography>
            <Typography variant="body1">
              EDC has people experienced in software development and business process management.
            </Typography>
            <div className={classes.heroSubtitleBox}>
              <Typography variant="caption">
                <span> 120+ </span>
                in-house talents
              </Typography>
              <Typography variant="caption">
                <span> 20+ </span>
                big happy clients
              </Typography>
              <Typography variant="caption">
                <span> 25+ </span>
                years of experience
              </Typography>
            </div>
          </div>
          <div className={classes.heroTextContainer} style={{ animationDelay: '7s' }}>
            <Typography component="h4">
              Meet our team. We are a Romanian company with offices in Cluj, Transylvania
            </Typography>
            <Typography variant="body1">
              Reach out to us to find out why EDC is a quality provider,
              respected, reliable partner and employer.
            </Typography>
          </div>
          <div className={classes.heroTextContainer} style={{ animationDelay: '14s' }}>
            <Typography component="h4">
              Dedicated experts. Managed teams or augmented staff
            </Typography>
            <Typography variant="body1">
              We share with you our experts for your projects.
            </Typography>
          </div>
          <div className={classes.heroTextContainer} style={{ animationDelay: '21s' }}>
            <Typography component="h4">
              In a world full of “smart” everything, we help your products to get smart-
              <span>er</span>
            </Typography>
            <Typography variant="body1">
              Smart technology. Smart connectivity. Smart fitness, homes, offices, wearables,
              appliances. Smart everything. We have the extensive capability to turn any device
              with an electronic component into a “smart” device.
            </Typography>
          </div>
          <div className={classes.heroTextContainer} style={{ animationDelay: '28s' }}>
            <Typography component="h4">
              Your trusted software development partner
            </Typography>
            <Typography variant="body1">
              We build your solution within scope, timeline and budget.
              Custom-made solutions delivered end-to-end.
            </Typography>
          </div>
          <div className={classes.heroTextContainer} style={{ animationDelay: '35s' }}>
            <Typography component="h4">
              Process consulting, strategic planning and requirements for project setup
            </Typography>
            <Typography variant="body1">
              We offer guidance and options for you to move forward with your: process,
              setup, rampup, quality assurance, customer success.
            </Typography>
          </div>
        </div>
      </div>
      <div className={classes.heroImageWrapper}>
        <div className={classes.container} style={{ animationDelay: '0s' }}>
          <img src={neusoftEDCHero} alt="neusoft hero" className={classes.heroImage} />
        </div>
        <div className={classes.container} style={{ animationDelay: '7s' }}>
          <img
            src={neusoftIsMeHero}
            alt="neusoft hero"
            className={classes.heroImage}
          />
        </div>
        <div className={classes.container} style={{ animationDelay: '14s' }}>
          <img src={neusoftTalentHero} alt="neusoft hero" className={classes.heroImage} />
        </div>
        <div className={classes.container} style={{ animationDelay: '21s' }}>
          <img src={neusoftSmartHero} alt="neusoft hero" className={classes.heroImage} />
        </div>
        <div className={classes.container} style={{ animationDelay: '28s' }}>
          <img src={neusoftWareHero} alt="neusoft hero" className={classes.heroImage} />
        </div>
        <div className={classes.container} style={{ animationDelay: '35s' }}>
          <img src={neusoftBusinessHero} alt="neusoft hero" className={classes.heroImage} />
        </div>
      </div>
      <ScrollDownArrow navbarRef={navbarRef} />
    </div>
  );
}

Hero.propTypes = {
  navbarRef: PropTypes.shape({}),
};

Hero.defaultProps = {
  navbarRef: null,
};

export default Hero;

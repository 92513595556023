export const WHO_PATH = '/who';

export const WHAT_FIRST_PATH = '/smart-fitness';
export const WHAT_SECOND_PATH = '/internet-of-things';
export const WHAT_THIRD_PATH = '/automotive';
export const WHAT_FOURTH_PATH = '/business-process-services';

export const HOW_FIRST_PATH = '/software-development';
export const HOW_SECOND_PATH = '/augmented-teams';
export const HOW_THIRD_PATH = '/managed-teams';
export const HOW_FOURTH_PATH = '/business-consulting-support';

export const WHERE_PATH = '/where';

export const JOIN_LIFE_AT_NEUSOFT_PATH = '/life-at-neusoft';
export const JOIN_OPEN_POSITIONS_PATH = '/open-positions';
export const JOIN_INTERNSHIP_PATH = '/internship';

export const OPEN_POSITION_PATH = '/open-position';

export const WHAT_LINKS = [
  {
    text: 'SMART FITNESS',
    URL: WHAT_FIRST_PATH,
  },
  {
    text: 'INTERNET OF THINGS',
    URL: WHAT_SECOND_PATH,
  },
  {
    text: 'AUTOMOTIVE',
    URL: WHAT_THIRD_PATH,
  },
  {
    text: 'BUSINESS PROCESS SERVICES',
    URL: WHAT_FOURTH_PATH,
  },
];

export const HOW_LINKS = [
  {
    text: 'SOFTWARE DEVELOPMENT',
    URL: HOW_FIRST_PATH,
  },
  {
    text: 'AUGMENTED TEAMS',
    URL: HOW_SECOND_PATH,
  },
  {
    text: 'MANAGED TEAMS',
    URL: HOW_THIRD_PATH,
  },
  {
    text: 'BUSINESS SUPPORT',
    URL: HOW_FOURTH_PATH,
  },
];

export const JOIN_LINKS = [
  {
    text: 'LIFE @ NEUSOFT',
    URL: JOIN_LIFE_AT_NEUSOFT_PATH,
  },
  {
    text: 'OPEN POSITIONS',
    URL: JOIN_OPEN_POSITIONS_PATH,
  },
  {
    text: 'INTERNSHIP PROGRAM',
    URL: JOIN_INTERNSHIP_PATH,
  },
];

export const IMPRINT_PATH = '/imprint';
export const DATA_POLICY_PATH = '/data-policy';
export const COOKIES_POLICY_PATH = '/cookies-policy';
export const FAQ_PATH = '/faq';
export const CCTV_PATH = '/cctv';

export const blackRock = '#01012B';
export const stratos = '#00002E';
export const salmon = '#FF9061';
export const cerulean = '#07B2EB';
export const malibu = '#5CCAFA';
export const alabaster = '#FBFBFB';
export const grey = '#F2F2F2';
export const orange = '#FF8854';
export const white = '#FFFFFF';
export const terracota = '#DF7E54';
export const endeavour = '#005BAC';
export const boulder = '#797979';
export const alto = '#DEDEDE';
export const dodgerBlueLight = '#3CC1FC';
export const dodgerBlueDark = '#0F8EFF';
export const fruitSalad = '#4B9F70';
export const emeraldDark = '#5BC389';
export const emeraldLight = '#5CC489';
export const frenchPass = '#b6eaff';
export const regentStBlue = '#A2BEE0';
export const cyan = '#00CCFF';
export const gallery = '#EBEBEB';
export const silver = '#C4C4C4';
export const desertStorm = '#F2F2F1';
export const greyNickel = '#BFBFBE';
export const hoverMenuColor = '#F8F7F7';
export const hoverButtonShadow = '#00002EAA';
export const labelColor = '#6A6A6A';
export const labelBackgroundColor = '#EBEBEB';
export const saffron = '#F3D03E';
export const jacarta = '#362A5C';
export const viking = '#70BBDA';
export const blueBell = '#9B93CF';
export const lightningYellow = '#F9CA26';
export const seaPink = '#ECA4B0';
export const portage = '#9E8EF0';
export const tacha = '#D8D272';
export const ebony = '#0C1620';
export const yourPink = '#FFBDBE';
export const azureRadiance = '#098DF1';
export const gray = '#909090';
export const teamGoalGradient = `transparent linear-gradient(90deg, ${cyan} 0%, ${endeavour} 100%)`;
export const limeColor = '#CDDA68';
export const pinkColor = '#F29CC3';
export const blackColor = '#261E1C';
export const lemonadeColor = '#F6E74C';
export const blueShade = '#4373B3';
export const yellowMelon = '#EDBC33';
export const darkPink = '#F6274F';
export const blueShade2 = '#4070B0';
export const darkGreen = '#2B6A3F';
export const purpleColor = '#4F34FD';
export const greyishRed = '#D3AEA6';
export const lightOrange = '#F87654';
export const trendyPink = '#915898';
export const timelineLine = '#A7BDDD';

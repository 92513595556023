import React from 'react';
import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { alto, blackRock } from '../../colors';

const useStyles = makeStyles(() => ({
  root: {
    height: '0.625rem',
    width: '0.625rem',
    marginLeft: '0.625rem',
    backgroundColor: alto,
    borderRadius: '50%',
    '@media (max-width:600px)': {
      height: '0.313rem',
      width: '0.313rem',
      marginLeft: '0.438rem',
    },
    '@media (min-width:600px) and (orientation: portrait)': {
      height: '0.563rem',
      width: '0.563rem',
      marginLeft: '0.938rem',
    },
    '@media (orientation:landscape) and (max-device-width:900px)': {
      height: '0.313rem',
      width: '0.313rem',
    },
  },
  active: {
    backgroundColor: blackRock,
  },
}));

function Dot({ isActive }) {
  const classes = useStyles();
  return (
    <div className={`${classes.root} ${isActive ? classes.active : ''}`} />
  );
}

Dot.propTypes = {
  isActive: PropTypes.bool,
};

Dot.defaultProps = {
  isActive: false,
};

export default Dot;

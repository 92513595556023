import React, { useEffect, useRef, useState } from 'react';
import {
  Link,
} from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles, Typography } from '@material-ui/core';
import { Parallax } from 'react-scroll-parallax';
import AppButton from '../../common/button/AppButton';

const useStyle = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '0 auto',
    height: 'auto',
    '@media (orientation:landscape) and (min-width:2200px)': {
      width: '50%',
    },
    '@media (orientation:landscape) and (min-device-width:1200px) and (min-width:600px) and (max-width:1300px)': {
      width: '70%',
    },
    '@media (orientation: portrait)': {
      width: '80%',
    },
    '@media (orientation: landscape)': {
      width: '60%',
    },
    '@media(max-width : 1180px)  and (orientation : landscape)': {
      width: '80%',
    },
    '& a': {
      textDecoration: 'none',
    },
  },
  verticalInsideRow: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'space-between',
    padding: '5rem 0',
    '@media (orientation: portrait) and (max-width: 600px)': {
      flexDirection: 'column',
      padding: '3.5rem 0',
    },
    '@media (min-width: 600px)': {
      flexDirection: 'row',
      padding: '5rem 0',
    },
  },
  container: {
    width: '44.2%',
    height: '44.2%',
    display: 'flex',
    right: 0,
    justifyContent: 'flex-end',
    borderRadius: '0rem 6.875rem 0rem 0rem',
    boxShadow: '0px 10px 15px #00002E',
    animationName: '$rotateImage',
    animationDuration: '2s',
    animationTimingFunction: 'ease-in-out',
    '@media (max-width:600px)': {
      order: '0',
      width: '75%',
      height: '75%',
    },
    '@media (min-width:600px) and (max-width:1100px)': {
      width: '19rem',
      height: '19rem',
    },
    '@media (min-width:1000px) and (orientation: portrait)': {
      width: '23rem',
      height: '23rem',
    },
    '@media (orientation: landscape)': {
      width: '44.2%',
      height: '44.2%',
    },
    '@media (orientation: landscape) and (max-device-width:1000px)': {
      width: '40%',
      height: '40%',
    },
  },
  image: {
    width: '100%',
    height: '100%',
  },
  textBox: {
    zIndex: '3',
    width: '40%',
    '& button': {
      marginTop: '2rem',
      animationName: '$rotateImage',
      animationDuration: '2s',
      animationTimingFunction: 'ease-in-out',
    },
    '@media (max-width:600px)': {
      textAlign: 'center',
      width: '70%',
      order: '1',
    },
    '@media (min-width:600px) and (orientation: portrait)': {
      textAlign: 'left',
    },
    '@media (orientation: landscape) and (max-device-width:900px)': {
      width: '45%',
    },
  },
  text: {
    '@media (orientation: landscape)': {
      marginRight: 'unset',
      width: '100%',
    },
    zIndex: '3',
    overflow: 'hidden',
    '& h4': {
      animationName: '$move',
      animationDuration: ' 2s',
      fontStyle: 'italic',
      '@media (orientation: portrait) and (max-width:600px)': {
        marginTop: '3rem',
      },
    },
    '@media (orientation: portrait) and (max-width:600px)': {
      textAlign: 'center',
      alignSelf: 'flex-start',
    },
  },
  caption: {
    opacity: '0.6',
    marginTop: '1.875rem',
    textAlign: 'center',
  },
  '@keyframes move': {
    '0%': {
      transform: 'translateX(-70%)',
    },
    '100%': {
      transform: 'translateX(0%)',
    },
  },
  '@keyframes rotateImage': {
    '0%': {
      opacity: 0,
    },
    '100%': {
      opacity: 1,
    },
  },
}));

const OurMission = ({
  text, image, alt, buttonText, caption, settings, link,
}) => {
  const ref = useRef(null);
  const [isInView, setIsInView] = useState(false);
  const classes = useStyle();
  useEffect(() => {
    const visibilityObserver = new IntersectionObserver((entry) => {
      if (entry[0].isIntersecting) {
        setIsInView(true);
        visibilityObserver.disconnect();
      }
    }, {
      threshold: 0.25,
    });
    visibilityObserver.observe(ref.current);
  }, []);
  return (
    <div className={classes.wrapper} ref={ref}>
      {caption && <Typography variant="caption" className={classes.caption}>{caption}</Typography>}
      <Parallax y={['100px', '-100px']} disabled={!isInView}>
        <div className={classes.verticalInsideRow}>
          <div className={classes.textBox}>
            <div className={classes.text}>
              <Typography variant="h4">{text}</Typography>
            </div>
            <div>
              {link ? (
                <Link to={link}>
                  <AppButton
                    background={settings.backgroundButton}
                    backgroundOnHover={settings.backgroundOnHoverButton}
                    color={settings.colorButton}
                    colorOnHover={settings.colorOnHoverButton}
                    buttonHoverShadow={settings.buttonHoverShadowButton}
                    buttonFocusShadow={settings.buttonFocusShadow}
                    padding={settings.paddingButton}
                    paddingMobile={settings.paddingMobileButton}
                    paddingTablet={settings.paddingTabletButton}
                    border={settings.borderButton}
                    borderHover={settings.borderButtonHover}
                  >
                    <Typography variant="button">{buttonText}</Typography>
                  </AppButton>
                </Link>
              ) : (
                <a href="https://www.neusoft.com" target="_blank" rel="noreferrer">
                  <AppButton
                    background={settings.backgroundButton}
                    backgroundOnHover={settings.backgroundOnHoverButton}
                    color={settings.colorButton}
                    colorOnHover={settings.colorOnHoverButton}
                    buttonHoverShadow={settings.buttonHoverShadowButton}
                    buttonFocusShadow={settings.buttonFocusShadow}
                    padding={settings.paddingButton}
                    paddingMobile={settings.paddingMobileButton}
                    paddingTablet={settings.paddingTabletButton}
                    border={settings.borderButton}
                    borderHover={settings.borderButtonHover}
                  >
                    <Typography variant="button">{buttonText}</Typography>
                  </AppButton>
                </a>
              )}

            </div>
          </div>
          <div className={classes.container}>
            <img src={image} alt={alt} className={classes.image} />
          </div>
        </div>
      </Parallax>
    </div>
  );
};

OurMission.propTypes = {
  text: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  caption: PropTypes.string,
  settings: PropTypes.shape({
    backgroundButton: PropTypes.string.isRequired,
    backgroundOnHoverButton: PropTypes.string.isRequired,
    colorButton: PropTypes.string.isRequired,
    colorOnHoverButton: PropTypes.string.isRequired,
    buttonHoverShadowButton: PropTypes.string.isRequired,
    buttonFocusShadow: PropTypes.string.isRequired,
    paddingButton: PropTypes.string.isRequired,
    paddingMobileButton: PropTypes.string.isRequired,
    paddingTabletButton: PropTypes.string.isRequired,
    borderButton: PropTypes.string.isRequired,
    borderButtonHover: PropTypes.string,
  }).isRequired,
  link: PropTypes.string.isRequired,
};

OurMission.defaultProps = {
  caption: '',
};

export default OurMission;

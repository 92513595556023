import React, { useEffect, useState, useRef } from 'react';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import Header from '../Header/Header';
import {
  alabaster, white,
} from '../../colors';
import Footer from '../../common/Footer/Footer';
import Overview from './Overview';
import useAxios from '../../hooks/useAxios';
import RequiredSkills from './RequiredSkills';
import RequiredSkillsBonus from './RequiredSkillsBonus';
import WhyWorkWithUs from './WhyWorkWithUs';
import ApplyForm from './ApplyForm';
import ApplyFormMobile from './ApplyFormMobile';
import ScrollToTop from '../../common/ScrollToTop';

const useStyles = makeStyles(() => ({
  autoSection: {
    width: '100%',
    height: 'auto',
  },
  backgroundWhite: {
    backgroundColor: white,
  },
  backgroundAlabaster: {
    backgroundColor: alabaster,
  },
  applyFormPadding: {
    paddingTop: '10vh',
  },
}));

const JobTemplate = ({ id }) => {
  const classes = useStyles();
  const [job, setJob] = useState(null);
  const navbarRef = useRef();

  const { response } = useAxios({
    method: 'get',
    url: `/CW-API/jobs/${id}`,
    headers: JSON.stringify({ accept: 'application/json' }),
  });

  useEffect(() => {
    if (response) {
      setJob(response);
    }
  }, [response]);

  return (
    <>
      <Header navbarRef={navbarRef} />
      {
        job && (
          <>

            {job?.overviewTitle && (
              <section id="job-overview" className={clsx(classes.autoSection)}>
                <Overview job={job} navbarRef={navbarRef} />
              </section>
            )}

            {job?.requiredSkillsTitle && (
              <section
                id="job-required-skills"
                className={clsx(classes.autoSection)}
              >
                <RequiredSkills job={job} />
              </section>
            )}

            {job?.requiredSkillsBonusTitle && (
              <section
                id="job-required-bonus-skills"
                className={clsx(classes.autoSection, classes.backgroundWhite)}
              >
                <RequiredSkillsBonus job={job} />
              </section>
            )}

            {job?.whatWeOfferTitle && (
              <section
                id="working-with-us-benefits"
                className={clsx(classes.autoSection, classes.backgroundWhite)}
              >
                <WhyWorkWithUs job={job} />
              </section>
            )}

            <section
              id="apply-now"
              className={clsx(
                classes.autoSection,
                !job?.whatWeOfferTitle && classes.applyFormPadding,
              )}
            >
              <ApplyForm position={job?.overviewTitle} />
              <ApplyFormMobile />
            </section>
            <section
              id="footer"
              style={{ overflow: 'visible' }}
              className={clsx(classes.autoSection, classes.backgroundAlabaster)}
            >
              <Footer />
            </section>
            <ScrollToTop />
          </>
        )
      }
    </>
  );
};

JobTemplate.propTypes = {
  id: PropTypes.string.isRequired,
};

export default JobTemplate;

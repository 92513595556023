import React from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import CheckIcon from '@material-ui/icons/Check';
import {
  alto,
  dodgerBlueDark,
  dodgerBlueLight,
  emeraldDark,
  fruitSalad,
  cyan,
  terracota,
  white,
} from '../../colors';
import {
  BUTTON_DEFAULT_STATE,
  BUTTON_ERROR_STATE,
  BUTTON_SENDING_STATE,
  BUTTON_STATE_DISABLED,
  BUTTON_SUCCES_STATE,
} from '../constants/constants';

const useStyles = (props) => makeStyles(() => ({
  button: {
    background: props.background,
    color: white,
    height: '100%',
    width: '100%',
    borderRadius: '5vmin',
    '&:hover': {
      background: `${props.hoverBackground} !important`,
    },
    '&:active': {
      background: `${props.activeBackground} !important`,
    },
    '@media (orientation: portrait) and (max-width: 600px)': {
      borderRadius: '10vmin',
    },
    '&:disabled': {
      color: white,
    },
  },
  dotPulse: {
    position: 'relative',
    left: '-9999px',
    width: '5px',
    height: '5px',
    borderRadius: '2.5px',
    backgroundColor: white,
    color: white,
    boxShadow: `9999px 0 0 -5px ${white}`,
    animation: '$dotPulseAnimation 1.5s infinite linear',
    animationDelay: '.25s',

    '&:before': {
      content: '""',
      display: 'inline-block',
      position: 'absolute',
      top: '0',
      width: '5px',
      height: '5px',
      borderRadius: '2.5px',
      backgroundColor: white,
      color: white,
      boxShadow: `9984px 0 0 -5px ${white}`,
      animation: '$dotPulseBefore 1.5s infinite linear',
      animationDelay: '0s',
    },

    '&:after': {
      content: '""',
      display: 'inline-block',
      position: 'absolute',
      top: '0',
      width: '5px',
      height: '5px',
      borderRadius: '2.5px',
      backgroundColor: white,
      color: white,
      boxShadow: `10014px 0 0 -5px ${white}`,
      animation: '$dotPulseAfter 1.5s infinite linear',
      animationDelay: '.5s',
    },
  },

  '@keyframes dotPulseBefore': {
    '0%': {
      boxShadow: `9984px 0 0 -5px ${white}`,
    },
    '30%': {
      boxShadow: `9984px 0 0 2px ${white}`,
    },
    '60%': {
      boxShadow: `9984px 0 0 -5px ${white}`,
    },
    '100%': {
      boxShadow: `9984px 0 0 -5px ${white}`,
    },
  },

  '@keyframes dotPulseAnimation': {
    '0%': {
      boxShadow: `9999px 0 0 -5px ${white}`,
    },
    '30%': {
      boxShadow: `9999px 0 0 2px ${white}`,
    },
    '60%': {
      boxShadow: `9999px 0 0 -5px ${white}`,
    },
    '100%': {
      boxShadow: `9999px 0 0 -5px ${white}`,
    },
  },

  '@keyframes dotPulseAfter': {
    '0%': {
      boxShadow: `10014px 0 0 -5px ${white}`,
    },
    '30%': {
      boxShadow: `10014px 0 0 2px ${white}`,
    },
    '60%': {
      boxShadow: `10014px 0 0 -5px ${white}`,
    },
    '100%': {
      boxShadow: `10014px 0 0 -5px ${white}`,
    },
  },
}));

const buildButtonPropsByState = (state, background) => {
  switch (state) {
    case BUTTON_SENDING_STATE:
      return {
        background: fruitSalad,
        hoverBackground: fruitSalad,
        activeBackground: fruitSalad,
      };
    case BUTTON_SUCCES_STATE:
      return {
        background: emeraldDark,
        hoverBackground: emeraldDark,
        activeBackground: emeraldDark,
        children: <CheckIcon />,
      };
    case BUTTON_ERROR_STATE:
      return {
        background: terracota,
        hoverBackground: terracota,
        activeBackground: terracota,
        children: <Typography variant="button">TRY AGAIN</Typography>,
      };
    case BUTTON_STATE_DISABLED:
      return {
        background: alto,
        hoverBackground: alto,
        activeBackground: alto,
        children: <Typography variant="button">SEND</Typography>,
      };
    default:
      return {
        background: background || cyan,
        hoverBackground: background || dodgerBlueLight,
        activeBackground: background || dodgerBlueDark,
      };
  }
};

const AppStateButton = (props) => {
  const {
    children, callback, background, state,
  } = props;
  const buttonPropsByState = buildButtonPropsByState(state, background);
  const classes = useStyles(buttonPropsByState)();
  return (
    <Button
      onClick={callback}
      disableRipple
      className={classes.button}
      disabled={state === BUTTON_STATE_DISABLED}
    >
      {state === BUTTON_DEFAULT_STATE && children}
      {state === BUTTON_SENDING_STATE && <div className={classes.dotPulse} />}
      {state !== BUTTON_SENDING_STATE && state !== BUTTON_DEFAULT_STATE && (
        buttonPropsByState.children
      )}
    </Button>
  );
};

AppStateButton.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
  ]),
  state: PropTypes.string,
  background: PropTypes.string,
  callback: PropTypes.func,
};

AppStateButton.defaultProps = {
  children: null,
  state: BUTTON_DEFAULT_STATE,
  background: cyan,
  callback: () => { },
};

export default AppStateButton;

import React, { useMemo } from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { teamGoalGradient, white } from '../../../colors';

const useStyles = makeStyles({
  root: {
    padding: '19.8vh 20%',
    background: teamGoalGradient,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    '@media(orientation: portrait)': {
      padding: '4.5vh 10%',
    },

    '& *': {
      color: white,
    },
  },
  title: {
    fontWeight: 'bold',
    paddingBottom: '5.5vh',
  },
  description: {
    columnCount: 2,
    columnFill: 'balance',
    columnGap: '2.5vw',

    '@media(orientation: portrait) and (max-width: 600px)': {
      columnCount: 1,
      textAlign: 'center',
    },
  },
});

function WhyWorkWithUs({ job }) {
  const classes = useStyles();

  const whatWeOfferSentences = useMemo(() => job?.whatWeOfferDescription.split('\n'), [job?.whatWeOfferDescription]);

  return (
    <div className={classes.root}>
      <Typography variant="h3" className={classes.title}>{job.whatWeOfferTitle}</Typography>
      <div className={classes.description}>
        {whatWeOfferSentences.map((sentence, index) => (
          sentence?.trim() !== '' && (
            // eslint-disable-next-line react/no-array-index-key
            <Typography key={`what-we-offer-${index}`} variant="body1">{`${sentence}`}</Typography>
          )
        ))}
      </div>
    </div>
  );
}

WhyWorkWithUs.propTypes = {
  job: PropTypes.shape({
    whatWeOfferTitle: PropTypes.string,
    whatWeOfferDescription: PropTypes.string,
  }).isRequired,
};

export default WhyWorkWithUs;

import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import applyFormIcon from '../../../assets/images/apply-form-mobile-icon.svg';
import { trendyPink } from '../../../colors';

const useStyles = makeStyles({
  root: {
    padding: '9.7vh 10%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    '@media(min-width: 600px)': {
      display: 'none',
    },
  },
  text: {
    paddingTop: '3.9vh',
    textAlign: 'center',

    '& a': {
      color: trendyPink,
    },
  },
});

function ApplyFormMobile() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <img src={applyFormIcon} alt="apply-now" />
      <div className={classes.text}>
        <Typography variant="body1">
          It looks like you’re on a mobile device.
          Switch to a larger screen to apply directly on our website,
          or send your application at&nbsp;
          <a href="mailto:hello@neusoft.ro">hello@neusoft.ro</a>
          .
        </Typography>
      </div>
    </div>
  );
}

export default ApplyFormMobile;

import React from 'react';
import { makeStyles } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import { endeavour, stratos } from '../../colors';

const useStyles = makeStyles(() => ({
  container: {
    zIndex: '1 !important',
    left: '0 !important',
    display: 'flex',
    height: '100vh',
    width: '2rem !important',
    justifyContent: 'center',
    marginLeft: '1.98%',
    background: 'transparent !important',
    '@media (orientation: portrait)': {
      display: 'none !important',
    },
  },
  link: {
    color: 'inherit',
    marginTop: '1rem',
    '& svg': {
      width: '4vh',
      height: '4vh',
      fill: endeavour,
    },
    '&:hover': {
      '& svg': {
        fill: stratos,
      },
    },
  },
}));

function SocialMediaIconsBar() {
  const classes = useStyles();

  return (
    <AppBar id="social-media-bar" position="absolute" elevation={0} className={classes.container}>
      <a className={classes.link} href="https://www.instagram.com/neusoft_edc/" rel="noreferrer" target="_blank">
        <InstagramIcon />
      </a>
      <a className={classes.link} href="https://www.facebook.com/edc.cluj" rel="noreferrer" target="_blank">
        <FacebookIcon />
      </a>
      <a className={classes.link} href="https://www.linkedin.com/company/neusoft-edc/" rel="noreferrer" target="_blank">
        <LinkedInIcon />
      </a>
    </AppBar>
  );
}

export default SocialMediaIconsBar;

import React, { useState, useEffect, useContext } from 'react';
import Modal from '@material-ui/core/Modal';
import CookiePolicy from './CookiePolicy';
import CookieBanner from './CookieBanner';
import CookieConsent from '../../context/index';
import { COOKIES_POLICY_PATH } from '../paths';
import { contentInEnglish, contentInRomanian } from './CookiesPolicyContent';

const Cookies = () => {
  const [openReadMore, setOpenReadMore] = useState(false);
  const { openCookieBanner, setOpenCookieBanner } = useContext(CookieConsent);
  const changeView = (cookiesBanner = false, cookiesPolicy = false) => {
    setOpenCookieBanner(cookiesBanner);
    setOpenReadMore(cookiesPolicy);
  };
  const acceptCookies = () => {
    setOpenCookieBanner(false);
    setOpenReadMore(false);
    localStorage.setItem('consent', 'accepted');
  };
  const changeViewOnNavigation = () => {
    changeView(true, false);
  };
  useEffect(() => {
    if (localStorage.getItem('consent')) {
      setOpenCookieBanner(false);
    } else {
      setOpenCookieBanner(true);
    }
  }, []);
  const [toggleValue, setToggleValue] = useState('en');
  return (
    <div>
      {openReadMore && (
        <Modal
          open
        >
          <CookiePolicy
            changeView={changeView}
            language={toggleValue}
            changeLanguage={(value) => setToggleValue(value)}
            content={toggleValue === 'ro' ? contentInRomanian(changeViewOnNavigation) : contentInEnglish('', COOKIES_POLICY_PATH, changeViewOnNavigation)}
            title={toggleValue === 'ro' ? 'POLITICĂ DE UTILIZARE COOKIE-URI' : 'COOKIES POLICY'}
            subtitle={toggleValue === 'ro'
              ? 'Actualizat la '
              : 'Updated at'}
            displayButtons
            acceptCookies={acceptCookies}
          />
        </Modal>
      )}
      {openCookieBanner && (
        <CookieBanner changeView={changeView} acceptCookies={acceptCookies} />
      )}
    </div>

  );
};

export default Cookies;

import { useEffect } from 'react';
import { useMediaQuery } from '@material-ui/core';

export default function ChangeInitialScale() {
  const portraitOrientation = useMediaQuery('@media (max-width:600px) and (orientation:portrait) and (hover: none) and (pointer: coarse)');

  const updateInitialScale = () => {
    const viewportMeta = document.getElementById('viewport');
    if (portraitOrientation) {
      viewportMeta.setAttribute('content', 'width=device-width, initial-scale=1');
    } else {
      viewportMeta.setAttribute('content', 'width=device-width, initial-scale=0.75');
    }
  };

  useEffect(() => {
    updateInitialScale();
  }, [portraitOrientation]);

  return null;
}

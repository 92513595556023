import React, { useEffect, useRef, useState } from 'react';
import { Button, makeStyles, Typography } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { useForm } from 'react-hook-form';
import Checkbox from '@material-ui/core/Checkbox';
import CloseIcon from '@mui/icons-material/Close';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import IconButton from '@material-ui/core/IconButton';
import clsx from 'clsx';
import axios from 'axios';
import PropTypes from 'prop-types';
import {
  alto, blackRock, endeavour, grey, malibu, orange, stratos, trendyPink, white,
} from '../../../colors';
import applyNowImage from '../../../assets/images/apply-now-image.svg';
import AppStateButton from '../../../common/button/AppStateButton';
import {
  BUTTON_DEFAULT_STATE, BUTTON_ERROR_STATE,
  BUTTON_SENDING_STATE,
  BUTTON_STATE_DISABLED,
  BUTTON_SUCCES_STATE,
} from '../../../common/constants/constants';
import { ReactComponent as UploadLogo } from '../../../assets/images/upload-icon.svg';
import GDPR from '../../../common/Cookies/GDPR';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    width: '100%',

    '@media(orientation: portrait) and (max-width: 600px)': {
      display: 'none',
    },

    '@media(min-width: 600px) and (orientation: portrait)': {
      flexDirection: 'column',
    },
  },
  leftFlexItem: {
    background: grey,
    display: 'flex',
    flexDirection: 'column',
    padding: '10.1vh 0',
    flexBasis: '50%',
    alignItems: 'center',
    flex: '50%',

    '@media(min-width: 600px) and (orientation: portrait)': {
      padding: '2.9vh 0 5.37vh 0',
      flexDirection: 'row-reverse',
      justifyContent: 'space-evenly',

      '& img': {
        width: '40vw !important',
      },
    },

    '& img': {
      width: '26.5vw',
      paddingTop: '3.7vh',
    },
  },
  rightFlexItem: {
    background: white,
    display: 'flex',
    flexDirection: 'column',
    padding: '10.1vh 20.4vw 5vh 8.9vw',
    flexBasis: '50%',
    position: 'relative',

    '@media(min-width: 600px) and (orientation: portrait)': {
      padding: '4.88vh 17vw',
    },
  },
  title: {
    paddingBottom: '3.7vh',
    color: orange,
    fontWeight: 'bold',

    '@media(min-width: 600px) and (orientation: portrait)': {
      paddingBottom: '2.6vh',
    },
  },
  description: {
    width: '90%',

    '& a': {
      color: trendyPink,
      cursor: 'pointer',
    },
  },
  textWrapper: {
    paddingLeft: '16vw',

    '@media(min-width: 600px) and (orientation: portrait)': {
      paddingLeft: '0',
    },
  },
  buttonWrapper: {
    alignSelf: 'center',
    height: '3.88vh',
    '@media(min-width: 600px) and  (orientation: portrait)': { // tablet
      width: '15.88vw',
    },
    '@media (orientation: portrait) and (max-width: 600px)': { // mobile
      width: '24vw',
    },

    '& span': {
      padding: '0 0.5vw',
    },
  },
  gdprDetailsWrapper: {
    width: '29.32vw',
    display: 'flex',
    fontStyle: 'italic',
    paddingTop: '2.59vh',
    paddingBottom: '3vh',
    justifyContent: 'center',
    '& p': {
      fontSize: '1.4vmin',
      color: alto,
      width: '100%',
    },
    '& span': {
      textAlign: 'left',
    },
    '@media(orientation: portrait)': {
      width: '65vw !important',
    },
    '@media (orientation: portrait) and (max-width: 600px)': { // mobile
      paddingTop: '6vh',
      paddingBottom: '8vh',
    },
  },
  input: {
    width: '26vw',
    marginTop: '1vh',
    '& .MuiFormLabel-root': {
      color: stratos,
      fontFamily: 'neo-sans',
      fontSize: '1.25rem',
      opacity: '0.4',
    },
    '& .MuiInputLabel-shrink': {
      opacity: '1',
    },
    '& .MuiFormLabel-root.Mui-focused': {
      color: endeavour,
    },
    '& .MuiFormLabel-root.Mui-error': {
      color: orange,
    },
    '& .MuiInputBase-root': {
      color: blackRock,
      fontFamily: 'neo-sans',
      fontSize: '1.25rem',
      opacity: '1',
    },
    '& .Mui-focused': {
      '& fieldset': {
        borderColor: `${endeavour} !important`,
      },
    },
    '& .Mui-error': {
      '& fieldset': {
        borderColor: `${orange} !important`,
      },
      color: orange,
    },
    '@media(orientation: portrait)': {
      width: '65vw',
    },
    '@media (orientation: portrait) and (max-width: 600px)': { // mobile
      marginTop: 0,
    },
  },
  gdprWrapper: {
    height: 'auto',
    width: '26vw',
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    position: 'relative',
    paddingTop: '1.5vh',
    '& .MuiCheckbox-root': {
      padding: 0,
      color: stratos,
    },
    '& .Mui-checked': {
      color: `${malibu} !important`,
    },
    '& p > span': {
      color: endeavour,
      fontWeight: 'bold',

      '&:hover': {
        cursor: 'pointer',
      },
    },
    '& p': {
      lineHeight: 'initial',
      paddingLeft: '1.3vw',
    },
    '@media(orientation: portrait)': {
      width: '65vw !important',
    },
    '@media (orientation: portrait) and (max-width: 600px)': { // mobile
      paddingTop: '2vh',

      '& .MuiIconButton-label > svg': {
        width: '1.25rem',
        height: '1.25rem',
      },
    },
  },
  error: {
    marginTop: '0.2vh',
    marginBottom: '0.8vh',
    color: orange,
    height: '1.5vh',
    width: '26vw !important',
    textAlign: 'left !important',
    fontSize: '0.75rem',
    '@media(orientation: portrait)': {
      width: '65vw !important',
    },
    '@media (orientation: portrait) and (max-width: 600px)': { // mobile
      fontSize: '0.62rem',
    },
  },
  buttonRoot: {
    borderRadius: '6.25rem',
    border: `2px solid ${stratos}`,

    '& .MuiButton-label': {
      padding: '0 1.3vw',
    },

    '&:hover': {
      cursor: 'pointer',
      background: stratos,
      color: white,

      '& .MuiButton-startIcon': {

        '& svg path': {
          stroke: white,
        },
      },
    },
  },
  filesUploadWrapper: {
    padding: '2vh 0',
  },
  files: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
  },
  file: {
    borderRadius: '6.25rem',
    border: `2px solid ${endeavour}`,
    background: endeavour,
    color: white,

    '&:hover': {
      cursor: 'pointer',
      color: white,
      background: endeavour,
    },
  },
  circleButton: {
    display: 'flex',

    '& .MuiSvgIcon-root': {
      width: '1.5em !important',
      height: '1.5em !important',
      fill: endeavour,

      '&:hover': {
        cursor: 'pointer',
      },
    },
  },
  fileMarginRight: {
    margin: '0.5vw 1vw 0.5vw 0',
  },
  fileTypeMessage: {
    padding: '0.2vh 0',
    fontSize: '14px !important',
    opacity: 0.5,
  },
});

const FILES_UPLOAD_COUNT_LIMIT = 4;

function ApplyForm({ position }) {
  const classes = useStyles();
  const {
    register, errors, handleSubmit, watch, getValues, reset,
  } = useForm({
    mode: 'onChange',
    shouldFocusError: true,
  });
  const watchAllFields = watch();
  const hasErrors = () => errors.fullName || errors.email || errors.emailBody;
  const fieldsAreEmpty = () => watchAllFields.fullName === '' || watchAllFields.email === '' || watchAllFields.phone === '';
  const [gdprChecked, setGdprChecked] = useState(false);
  const [buttonState, setButtonState] = useState(BUTTON_DEFAULT_STATE);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const fileInputRef = useRef(null);
  const [gdprModalState, setGdprModalState] = useState(false);

  useEffect(() => {
    if (buttonState === BUTTON_SUCCES_STATE) {
      setTimeout(() => {
        setButtonState(BUTTON_STATE_DISABLED);
      }, 2300);
    } else if (hasErrors() || !gdprChecked || fieldsAreEmpty() || selectedFiles?.length === 0) {
      setButtonState(BUTTON_STATE_DISABLED);
    } else if (buttonState === BUTTON_STATE_DISABLED) {
      setButtonState(BUTTON_DEFAULT_STATE);
    }
  }, [errors.fullName, errors.email, errors.emailBody, gdprChecked, watchAllFields]);

  const uploadFiles = async () => {
    const result = await Promise.all(selectedFiles.map(
      async (file) => axios.post('CW-API/create-upload-url', { fileName: file.name, type: file.type }),
    ));

    await Promise.all(result.map(
      async (resultObject, index) => axios.put(resultObject?.data?.url, selectedFiles[index]),
    ));
  };

  const sendApplicantToHr = async (id) => {
    const fileNames = selectedFiles.map((file) => file.name);

    await axios.post('CW-API/send-applicant-to-hr', {
      id,
      fileNames,
    });
  };

  const onFormReset = () => {
    reset({
      fullName: '',
      email: '',
      phone: '',
      location: '',
    });
    setSelectedFiles([]);
    setGdprChecked(false);
  };

  const onSubmit = async () => {
    try {
      setButtonState(BUTTON_SENDING_STATE);
      const {
        fullName,
        email,
        phone,
        location,
      } = getValues();

      const response = await axios.post('CW-API/consent', {
        role: 'Candidate',
        firstName: fullName?.split(' ')[0],
        lastName: fullName?.split(' ')[1],
        phoneNumber: phone,
        email,
        location,
        position,
      });
      await uploadFiles();
      await sendApplicantToHr(response.data.id);
      setButtonState(BUTTON_SUCCES_STATE);
      onFormReset();
    } catch (e) {
      setButtonState(BUTTON_ERROR_STATE);
      console.log('Error when trying to apply', e);
    }
  };

  const validateFileSize = (file) => (file.size / 1024 / 1024) <= 7;

  const onChange = (event) => {
    const { files } = event.target;
    // allowed only one file per upload
    const extension = files?.[0]?.name?.split('.')?.[1];

    if (!validateFileSize(files[0])) return;

    if (['pdf', 'doc', 'docx'].indexOf(extension.toLowerCase()) >= 0) {
      setSelectedFiles([
        ...selectedFiles,
        ...Array.from(files),
      ]);
    }
  };

  const triggerInputClick = () => {
    fileInputRef?.current?.click();
  };

  const removeFileByIndex = (fileIndex) => {
    const filtered = selectedFiles.filter((value, index) => index !== fileIndex);
    setSelectedFiles(filtered);
  };

  const getFileName = (fileName) => {
    if (fileName.length > 10) return `${fileName.substring(0, 10)}...`;
    return fileName;
  };

  const openGDPR = () => {
    setGdprModalState(true);
  };

  const closeGDPR = () => {
    setGdprModalState(false);
  };

  return (
    <div className={classes.root}>
      <GDPR callback={closeGDPR} state={gdprModalState} />
      <div className={classes.leftFlexItem}>
        <div className={classes.textWrapper}>
          <Typography variant="h3" className={classes.title}>Apply now</Typography>
          <Typography variant="body1" className={classes.description}>
            You can also send your resume at&nbsp;
            <a href="mailto:hello@neusoft.ro">hello@neusoft.ro</a>
          </Typography>
        </div>
        <img alt="apply-now" src={applyNowImage} />
      </div>
      <div className={classes.rightFlexItem}>
        <TextField
          className={classes.input}
          name="fullName"
          label="Name"
          type="text"
          variant="outlined"
          required
          error={!!errors.fullName}
          inputRef={register({
            required: 'Enter your name',
            pattern: {
              value: /^(?=.{1,40}$)[a-zA-Z]+(?:[-' ][a-zA-Z]+)*$/i,
              message: 'Enter a valid name',
            },
          })}
        />
        <Typography
          variant="caption"
          className={classes.error}
        >
          {errors.fullName ? errors.fullName.message : ''}
        </Typography>

        <TextField
          className={classes.input}
          name="email"
          label="E-mail"
          type="email"
          variant="outlined"
          required
          error={!!errors.email}
          inputRef={register({
            required: 'Enter your e-mail',
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
              message: 'Enter a valid e-mail address',
            },
          })}
        />
        <Typography
          variant="caption"
          className={classes.error}
        >
          {errors.email ? errors.email.message : ''}
        </Typography>

        <TextField
          className={classes.input}
          name="phone"
          label="Phone"
          type="text"
          variant="outlined"
          required
          error={!!errors.phone}
          inputRef={register({
            required: 'Enter your phone',
            pattern: {
              value: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/g,
              message: 'Enter a valid phone number',
            },
          })}
        />
        <Typography
          variant="caption"
          className={classes.error}
        >
          {errors.phone ? errors.phone.message : ''}
        </Typography>

        <TextField
          className={classes.input}
          name="location"
          label="Location"
          type="text"
          variant="outlined"
          inputRef={register()}
        />

        <div className={classes.filesUploadWrapper}>
          {
            selectedFiles.length === 0
            && (
              <>
                <Button
                  className={classes.buttonRoot}
                  startIcon={<UploadLogo />}
                  onClick={triggerInputClick}
                  disableRipple
                >
                  Upload files&#42;
                  <input type="file" onChange={onChange} hidden ref={fileInputRef} />
                </Button>
              </>
            )
          }
          {
            selectedFiles.length > 0 && (
              <div className={classes.files}>
                {selectedFiles.map((file, index) => (
                  <>
                    <Button
                      startIcon={<CloseIcon />}
                      className={clsx(classes.file,
                        index !== selectedFiles.length - 1 && classes.fileMarginRight)}
                      onClick={() => removeFileByIndex(index)}
                      disableRipple
                    >
                      {getFileName(file.name)}
                    </Button>
                    {index === selectedFiles.length - 1
                      && selectedFiles.length < FILES_UPLOAD_COUNT_LIMIT && (
                        <div className={classes.circleButton}>
                          <IconButton
                            onClick={triggerInputClick}
                            disableFocusRipple
                            disableRipple
                            style={{ backgroundColor: 'transparent' }}
                          >
                            <AddCircleIcon />
                            <input type="file" onChange={onChange} hidden ref={fileInputRef} />
                          </IconButton>
                        </div>
                        // eslint-disable-next-line indent
                      )}
                  </>
                ))}
              </div>
            )
          }
        </div>

        <Typography className={classes.fileTypeMessage} variant="caption">Accepted files: .doc, .docx, .pdf with size no bigger than 7MB</Typography>

        <div className={classes.gdprWrapper}>
          <Checkbox
            disableRipple
            checked={gdprChecked}
            onChange={() => setGdprChecked(!gdprChecked)}
          />
          <Typography variant="body1">
            I agree with
            {' '}
            {/* eslint-disable-next-line max-len */}
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <span onClick={openGDPR}>GDPR</span>
            {' '}
            Terms and Conditions
          </Typography>
        </div>

        <div className={classes.gdprDetailsWrapper}>
          <Typography variant="caption">
            By expressing consent explicitly, you agree to allow Neusoft EDC
            to store your personal data in our Candidates Database for up to 24 months,
            as well as process it in order to achieve the purpose of this Agreement.
          </Typography>
        </div>

        <div className={classes.buttonWrapper}>
          <AppStateButton
            state={buttonState}
            callback={handleSubmit(onSubmit)}
          >
            <Typography variant="button">SEND</Typography>
          </AppStateButton>
        </div>
      </div>
    </div>
  );
}

ApplyForm.propTypes = {
  position: PropTypes.string.isRequired,
};

export default ApplyForm;

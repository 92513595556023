import React, { useEffect, useRef, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { makeStyles, Typography, useMediaQuery } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Parallax } from 'react-scroll-parallax';
import {
  blackRock, white, labelColor, labelBackgroundColor,
} from '../../colors/index';
import AppButton from '../button/AppButton';

const useStyles = makeStyles(() => ({
  vertical: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '60%',
    margin: '0 auto',
    height: 'auto',
    '@media (max-width:600px)': {
      width: '80%',
    },
    '@media(max-width : 1180px)  and (orientation : landscape)': {
      width: '80%',
    },
  },
  verticalInsideRow: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
    '@media (max-width:600px)': {
      paddingTop: '0',
    },
    '@media (min-width:600px) and (orientation: portrait)': {
      paddingTop: '6.5rem',
    },
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    position: 'relative',
    '@media (orientation: landscape)': {
      flexDirection: 'row',
    },
    '@media (orientation: portrait)': {
      flexDirection: 'column',
      width: '100%',
    },
    '@media (max-width:600px)': {
      justifyContent: 'flex-start',
    },
    '@media (min-width:600px) and (orientation: portrait)': {
      justifyContent: 'flex-end',
    },
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    top: '-6rem',
    zIndex: '3',
    fontWeight: '0',
    '@media (orientation: portrait)': {
      flexDirection: 'column',
      justifyContent: 'center',
      width: '100%',
    },
  },
  icon: {
    width: '5.188rem',
    opacity: '0.8',
    zIndex: '4',
    position: 'absolute',
    '@media (orientation: landscape)': {
      top: '75%',
    },
    '@media (orientation: portrait)': {
      top: '87%',
      left: '10%',
      width: '4.5rem',
    },
    '@media (max-width:600px)': {
      position: 'relative',
      opacity: '0.8',
      left: '0',
      top: '0',
      paddingTop: '0.5rem',
      transform: 'translateX(-50%)',
      margin: '1rem 0',
      marginTop: '0',
      width: '1.875rem',
    },
    '@media (orientation: landscape) and (max-width:1180px)': {
      width: '4.5rem',
    },
  },
  title: {
    '@media (orientation: portrait) and (max-width:600px)': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  '@media (orientation: portrait)': {
    rowReverse: {
      flexDirection: 'column',
    },
  },
  '@media (orientation: landscape)': {
    rowReverse: {
      flexDirection: 'row-reverse !important',
    },
  },
  titleWrapper: {
    '@media (min-width:600px)': {
      zIndex: '4',
      top: '-28%',
      position: 'absolute',
    },
    '@media (min-width:600px) and (orientation: portrait)': {
      top: '5%',
    },
  },
  rowTitle: {
    '@media (orientation: portrait)': {
      left: '10%',
    },
  },
  rowReverseTitle: {
    '@media (min-width:600px)': {
      textAlign: 'right',
      alignSelf: 'flex-end',
    },
    '@media (orientation: portrait)': {
      right: '10%',
    },
  },
  labelWrapperReverse: {
    width: 'auto',
    display: 'flex',
    justifyContent: 'flex-end',
    '@media (orientation: portrait) and (max-width:600px)': {
      justifyContent: 'flex-start',
    },
  },
  labelWrapperInitial: {
    width: 'auto',
    display: 'flex',
    justifyContent: 'flex-start',
  },
  reverseIcon: {
    alignSelf: 'center',
    '@media (min-width:600px)': {
      alignSelf: 'center',
    },
    '@media (max-width:600px)': {
      left: '0',
    },
    '@media (min-width:600px) and (orientation: portrait)': {
      left: '10%',
    },
  },
  labelWrapper: {
    width: 'auto',
    '& span': {
      backgroundColor: labelBackgroundColor,
      borderRadius: '0.938rem',
      color: labelColor,
      padding: '0.2rem 0.8rem',
      display: 'block',
      '@media (orientation: portrait) and (max-width: 600px)': {
        lineHeight: '0.75rem',
        fontSize: '0.625rem',
      },
    },
    '@media (orientation: portrait) and (max-width: 600px)': {
      marginTop: '1rem',
      marginBottom: '1rem',
      marginLeft: '10%',
    },
  },
  description: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    margin: '10.4vw 0',
    '& p': {
      marginBottom: 0,
      width: '85%',
    },
    '@media (orientation: portrait)': {
      alignItems: 'center',
      textAlign: 'left',
      margin: '2.5rem 0',
      '@media (max-width:600px)': {
        marginTop: '0',
      },
      '& p': {
        marginBottom: 0,
        width: '100%',
      },
    },
    '@media (min-device-width:2000px)': {
      margin: '14vw 0',
    },
  },
  reverseDescription: {
    marginLeft: '8%',
    '& p': {
      width: '100%',
    },
    '@media (orientation: portrait)': {
      marginLeft: '0',
      textAlign: 'left',
    },
  },
  imageContainer: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    width: '100%',
    '@media (orientation: landscape)': {
      width: '29.5vw',
    },
    '@media (orientation: portrait)': {
      order: '-1',
      marginTop: '0',
      width: '100vw',
      maxHeight: '20rem',
      height: 'auto',
      overflow: 'hidden',
    },
    '@media (max-width:600px)': {
      maxHeight: '12.5rem',
    },
    '@media (min-width:600px) and (orientation: portrait)': {
      maxHeight: '20rem',
    },
    '@media(max-width : 1180px)  and (orientation : landscape)': {
      width: '40vw',
    },
  },
  image: {
    width: '100%',
    height: 'auto',
  },
  button: {
    marginTop: '4%',
    width: '7.625rem',
    '& a': {
      textDecoration: 'none',
      display: 'block',
    },
    '@media (orientation: portrait)': {
      marginTop: '6%',
      width: 'auto',
    },
  },
  '@keyframes moveToTop': {
    '0%': {
      transform: 'translateY(100vh)',
    },
    '100%': {
      transform: 'translateY(0)',
    },
  },
  '@keyframes fadeInMoveToTop': {
    '0%': {
      opacity: 0,
      transform: 'translateY(100vh)',
    },
    '80%': {
      opacity: 0.3,
    },
    '100%': {
      opacity: 1,
      transform: 'translateY(0)',
    },
  },
  animateTitleToTop: {
    transform: 'translateY(100vh)',
    animationName: '$moveToTop',
    animationDuration: '1s',
    animationIterationCount: '1',
    animationTimingFunction: 'ease-in-out',
    animationFillMode: 'forwards',
  },
  animateImageAndDescription: {
    transform: 'translateY(100vh)',
    animationName: '$fadeInMoveToTop',
    animationDuration: '1s',
    animationDelay: '0.3s',
    animationIterationCount: '1',
    animationTimingFunction: 'ease-in-out',
    animationFillMode: 'forwards',
  },
  hideElement: {
    visibility: 'hidden',
    transform: 'translateY(100vh)',
  },
}));
const Industry = (
  {
    title,
    description,
    label,
    link,
    image,
    tabletImage,
    icon,
    alt,
    isReverse,
  },
) => {
  const ref = useRef(null);
  const location = useLocation();
  const [isInView, setIsInView] = useState(false);
  const classes = useStyles();
  const portraitOrientation = useMediaQuery('@media (min-width:600px) and (orientation: portrait)');
  const mobileView = useMediaQuery('@media (orientation: portrait) and (max-width:600px)');

  useEffect(() => {
    const visibilityObserver = new IntersectionObserver((entry) => {
      if (entry[0].isIntersecting) {
        setIsInView(true);
        visibilityObserver.disconnect();
      }
    }, {
      threshold: 0.15,
    });

    visibilityObserver.observe(ref.current);
  }, []);

  return (
    <div ref={ref} className={classes.vertical}>
      <div className={isReverse ? `${classes.row} ${classes.rowReverse}` : classes.row}>
        <Parallax y={['100px', '-100px']} disabled={!isInView || mobileView || portraitOrientation}>
          <div className={`${classes.verticalInsideRow}`}>

            <span className={`
          ${classes.titleWrapper}
           ${isReverse ? classes.rowReverseTitle : classes.rowTitle} 
          ${isInView ? classes.animateTitleToTop : classes.hideElement} 
          `}
            >
              <div className={`${classes.labelWrapper}   
              ${isReverse ? classes.labelWrapperReverse : classes.labelWrapperInitial}`}
              >
                <Typography variant="caption">{label}</Typography>
              </div>
              {
                mobileView ? (
                  <div
                    className={classes.titleContainer}
                  >
                    <Typography variant="h2" className={classes.title}>
                      {title.map((item) => (`${item} `))}
                    </Typography>
                  </div>
                )
                  : title.map(
                    (word) => (
                      <div
                        key={word}
                        className={classes.titleContainer}
                      >
                        <Typography variant="h2" className={classes.title}>
                          {word}
                        </Typography>
                      </div>
                    ),
                  )
              }
            </span>
            <div className={`${classes.imageContainer}  ${isInView ? classes.animateImageAndDescription : classes.hideElement} `}>
              <img
                src={portraitOrientation ? tabletImage : image}
                alt={alt}
                className={`
          ${classes.image} 
          `}
              />
            </div>
            {portraitOrientation && (
              <img
                src={icon}
                alt={alt}
                className={`
          ${classes.icon}
           ${isReverse ? classes.reverseIcon : ''} 
          ${isInView ? classes.animateTitleToTop : classes.hideElement} 
          `}
              />
            )}
          </div>
        </Parallax>
        {!portraitOrientation && (
          <img
            src={icon}
            alt={alt}
            className={`
          ${classes.icon}
           ${isReverse ? classes.reverseIcon : ''} 
          ${isInView ? classes.animateTitleToTop : classes.hideElement} 
          `}
          />
        )}
        <div className={`
          ${classes.description} 
          ${!isReverse ? classes.reverseDescription : ''} 
          ${isInView ? classes.animateImageAndDescription : classes.hideElement} 
          `}
        >
          <Typography variant="body1" paragraph>
            {description}
          </Typography>
          {link && (
            <div className={classes.button}>
              <Link to={{ pathname: `${link}`, state: { prevPath: location.pathname } }}>
                <AppButton
                  background="transparent"
                  backgroundOnHover={blackRock}
                  color={blackRock}
                  colorOnHover={white}
                  border={`2px solid ${blackRock}`}
                  borderHover={`2px solid ${blackRock}`}
                  paddingTablet="0.813rem 2.438rem"
                  paddingMobile="0.438rem 1.5rem"
                >
                  <Typography variant="button">More</Typography>
                </AppButton>
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

Industry.propTypes = {
  title: PropTypes.arrayOf(PropTypes.string).isRequired,
  description: PropTypes.string.isRequired,
  label: PropTypes.string,
  link: PropTypes.string,
  image: PropTypes.string.isRequired,
  tabletImage: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  isReverse: PropTypes.bool,
};

Industry.defaultProps = {
  isReverse: false,
  label: '',
  link: '',
};

export default Industry;

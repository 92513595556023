import vahaClientIcon from '../../../assets/images/vaha-client-icon.svg';
import egymClientIcon from '../../../assets/images/egym-client-icon.svg';
import kunoClientIcon from '../../../assets/images/KUNO-client-icon.svg';
import clujITClientIcon from '../../../assets/images/CLUJ-IT-client-icon.svg';
import dacClientIcon from '../../../assets/images/DAC-client-icon.svg';
import icebergClientIcon from '../../../assets/images/iceberg-client-icon.png';
import ntsClientIcon from '../../../assets/images/NTS-client-icon.svg';
import pixformanceClientIcon from '../../../assets/images/Pixformance-client-icon.svg';
import transilvaniaITClientIcon from '../../../assets/images/Transilvania-IT-client-icon.png';
import mapsTestingIcon from '../../../assets/images/maps-testing-icon.svg';

const testimonials = [
  {
    logo: vahaClientIcon,
    title: 'Smart Fitness',
    description: 'Since I am in this business I have worked with hundreds of developers and have found that Neusoft EDC team has some of the most professional, flexible, and capable developers I have ever worked with. I can’t recommend them enough. They’ve worked with me always on budget and timeline, helping my business succeed and meet deadlines.',
    author: 'Călin Popescu, CTO & Co-founder',
    location: 'Berlin, Germany',
    logoWidthPortrait: '40%',
  },
  {
    logo: kunoClientIcon,
    title: 'Financial & HR Services',
    description: 'After successfully collaborating with Neusoft EDC at my previous job, I decided to work with Neusoft at KUNO to develop the KUNO operating system from scratch, which was without a doubt one of the best decisions we made. The level of quality, dependability, and professionalism is always very high. The team is completely committed to the success of the KUNO OS. As a result, I strongly recommend working with Neusoft EDC, and I would choose them again for any project at any time.',
    author: 'Sebastian Timm, Co-founder & CPO',
    location: 'Berlin, Germany',
    logoWidthPortrait: '40%',
  },
  {
    logo: pixformanceClientIcon,
    title: 'Smart Fitness',
    description: 'Extremely happy with the team. I’ve been working with them for a while and their expertness and patience was hugely important for us as we rolled out our complex & innovative project. They are a cool organization that found the proper resources to meet our needs instead of forcing a pre-engineered solution on us. I always recommend them.',
    author: 'Călin Popescu, CTO & Co-founder',
    location: 'Berlin, Germany',
    logoWidthPortrait: '50%',
  },
  {
    logo: egymClientIcon,
    title: 'Smart Gym',
    description: 'In my own experience, working with Neusoft EDC, they are highly professional but also very friendly and open to all the feedbacks we give them. I always found a friendly ear listening when it comes to some issues we want to get solutions for, whether it is directly or indirectly related to Neusoft. I like a lot that our collaboration has always been solution-oriented and is very productive. It is a pleasure working with Neusoft. Looking forward to working together for many more years to come.',
    author: 'Sharad Jain, Software Lean Delivery Agent',
    location: 'Munich, Germany',
    logoWidthPortrait: '40%',
  },
  {
    logo: dacClientIcon,
    title: 'Blockchain, AI, ML, IoT',
    description: 'In a Proof-of-Concept project, together with Neusoft’s EDC help, we proved that our solution can be seamlessly implemented into different IT landscapes, including automotive or IoT. We were particularly impressed by the know-how, technical skill level, and an amazing can-do attitude presented by our partners from EDC’s team.',
    author: 'Krzysztof Radecki, Chief Technology Officer',
    location: 'Gdansk, Poland',
    logoWidthPortrait: '40%',
  },
  {
    logo: clujITClientIcon,
    title: 'IT&C Industry Cluster',
    description: 'I know Neusoft EDC with their Cluj-based team as an active actor of the local innovation ecosystem and member of Cluj IT Cluster community for 2+ years. We’ve closely worked together on various local and international projects and initiatives for private sector, like Culturepreneurs under Cluj Future of Work, matchmaking & networking events, or Smart City Work Group under Cluj IT. We’re happy to have discovered a partner who prioritizes mutual support for growth and cooperation.',
    author: 'Andrei Kelemen, CEO Cluj IT Cluster & COO',
    location: 'DIH4Society, Cluj, Romania',
    logoWidthPortrait: '40%',
  },
  {
    logo: transilvaniaITClientIcon,
    title: 'IT&C Industry Cluster',
    description: 'Neusoft EDC has been a member of Transilvania IT Cluster since 2020 and ever since we were happy to see their willingness, desire and involvement in networking, trainings & sharing resources initiatives. We really like and support this attitude!',
    author: 'Bianca Muntean, Cluster Manager',
    location: 'Cluj, Romania',
    logoWidth: 'auto',
    logoWidthPortrait: '50%',
  },
  {
    logo: icebergClientIcon,
    title: 'Technology & Innovation Consulting',
    description: 'Part of our acceleration & digitalization activity is to connect companies in the IT&C sector, to find solutions together and generate sustainable innovation. We really like when things come together in partnerships and networking like in the case of Neusoft EDC. We worked together on an international project focusing on blockchain in the naval sector and it was a great experience, with ease of collaboration and a sense of teamwork all along. We surely will work together again.',
    author: 'Zaki Milhem, Chief Innovation Officer',
    location: 'Brasov, Romania',
    logoWidthPortrait: '40%',
  },
  {
    logo: ntsClientIcon,
    title: 'Web Services & Marketing',
    description: 'The thing we like the most about our Romanian colleagues is that they are very flexible, tech savvy, very quality-oriented and deliver as promised. We really enjoy working together!',
    author: 'Anjelika Ljabach, Chief Sales Officer',
    location: 'Munich, Germany',
    logoWidth: '30%',
    logoWidthPortrait: '25%',
  },
  {
    logo: mapsTestingIcon,
    title: 'Automotive',
    description: 'I really appreciate working with the Neusoft EDC team. Our cooperation with the team has a long history, several projects date back to early 2013. Neusoft EDC team provides reliable top quality testing services. The remote team excels at communication with geographical or cultural barriers not even noticeable. I would particularly like to emphasize the excellent project management, the high level of flexibility and customer orientation as well as the punctual delivery going on for years. The Neusoft EDC team is a trusted partner also for our future collaborations.',
    author: 'Stefan Feser, Team Lead Map Services',
    location: 'Hamburg, Germany',
    logoWidth: '30%',
    logoWidthPortrait: '25%',
  },
];
export default testimonials;

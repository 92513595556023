import React, { useRef } from 'react';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import Header from '../Header/Header';
import {
  grey, stratos, alabaster, white, terracota, hoverButtonShadow,
} from '../../colors';
import Industry from '../../common/Industry/Industry';
import Missions from './Missions';
import OurMission from './OurMission';
import CompanyDescription from './CompanyDescription';
import Footer from '../../common/Footer/Footer';
import smartFitness from '../../assets/images/smartFitness.jpg';
import smartFitnessTablet from '../../assets/images/smart-fitness-tablet.jpg';
import iot from '../../assets/images/iot.jpg';
import iotTablet from '../../assets/images/iot-tablet.jpg';
import automotive from '../../assets/images/automotive.jpg';
import automotiveTablet from '../../assets/images/automotive-tablet.jpg';
import bpo from '../../assets/images/bpo.jpg';
import bpoTablet from '../../assets/images/bpo-tablet.jpg';
import smartFitnessIcon from '../../assets/images/smart-fitness-icon.svg';
import iotIcon from '../../assets/images/iot-icon.svg';
import automotiveIcon from '../../assets/images/automotive-icon.svg';
import bpoIcon from '../../assets/images/bpo-icon.svg';
import ourMission from '../../assets/images/our-mission.jpg';
import SocialMediaIconsBar from './SocialMediaIconsBar';
import Hero from '../../common/Hero/Hero';
import companyDescription from '../../assets/images/technology-background.jpg';
import BusinessGrowth from './BusinessGrowth';
import businessGrowthWeb from '../../assets/images/business-growth.png';
import businessGrowthTablet from '../../assets/images/business-growth-tablet.png';
import businessGrowthMobile from '../../assets/images/business-growth-mobile.png';
import Testimonials from './Testimonials';
import ScrollToTop from '../../common/ScrollToTop';
import { WHERE_PATH } from '../../common/paths';

const useStyles = makeStyles(() => ({
  section: {
    width: '100%',
    height: '100vh',
    overflow: 'hidden',
    position: 'relative',
  },
  autoSection: {
    width: '100%',
    height: 'auto',
  },
  backgroundGrey: {
    backgroundColor: grey,
  },
  backgroundWhite: {
    backgroundColor: white,
  },
  heightAuto: {
    height: 'auto',
  },
  backgroundAlabaster: {
    backgroundColor: alabaster,
  },
  backgroundStratos: {
    backgroundColor: stratos,
  },
  relativeSection: {
    position: 'relative',
  },
}));

const Home = () => {
  const classes = useStyles();
  const navbarRef = useRef();

  return (
    <>
      <Header navbarRef={navbarRef} />
      <SocialMediaIconsBar />
      <section id="hero-neusoft-is-me" className={clsx(classes.section, classes.backgroundGrey, classes.relativeSection)}>
        <Hero navbarRef={navbarRef} />
      </section>
      <section id="technology" className={clsx(classes.autoSection, classes.backgroundAlabaster)}>
        <CompanyDescription
          images={{
            web: companyDescription,
            tablet: companyDescription,
            mobile: companyDescription,
          }}
          settings={{
            rowWidth: '50%',
            rowWidthTablet: '80%',
            padding: '0 20%',
            paddingMobile: '0 15%',
            backgroundOpacity: '0.25',
            backgroundOpacityPortrait: '0.4',
            backgroundPosition: 'right',
          }}
          alt="companyDescription"
          description="Software has been at the root of our growth, from the very first day of our business journey. We believe in healthy and connected communities, so delivering customized solutions so easy to use that the customers can see the value and want to join-in has always been our passion."
          title="Life is too short for bad software"
        />
      </section>
      <section id="clients" className={clsx(classes.autoSection, classes.backgroundGrey)}>
        <Testimonials />
      </section>

      <section id="smart-fitness" className={clsx(classes.section, classes.backgroundAlabaster, classes.heightAuto)}>
        <Industry
          title={['Smart', 'Fitness']}
          description="We have been working for over a decade with the best producers of smart gym, fitness tech, connected equipment and wearables to deliver the latest supporting software and apps in the fitness technology world. With our smart fitness solutions, both home and gym workout experience is improved, helping fitness users get the best out of technology advances in their health and fitness routines."
          label="NEUSOFT TECH"
          link="/smart-fitness"
          image={smartFitness}
          tabletImage={smartFitnessTablet}
          icon={smartFitnessIcon}
          alt="Smart fitness"
        />
      </section>

      <section id="iot" className={clsx(classes.section, classes.backgroundGrey, classes.heightAuto)}>
        <Industry
          title={['IoT & Cloud']}
          description="Smart homes, offices, appliances, wearables. People increasingly need to interact in own lives and spaces with a “smart” technology and connectivity approach. Our services are part of the global trend to automate, integrate and make equipment intelligent. We develop fully integrated software solutions, with backend, creation, design, sensor integration, development and testing of web and mobile applications."
          label="NEUSOFT TECH"
          link="/internet-of-things"
          image={iot}
          tabletImage={iotTablet}
          icon={iotIcon}
          alt="iot"
          isReverse
        />
      </section>
      <section id="automotive" className={clsx(classes.section, classes.backgroundAlabaster, classes.heightAuto)}>
        <Industry
          title={['Automotive']}
          description="Vehicle occupants expect to be entertained, connected and able to seamlessly access information and content from a variety of sources. Our solutions are addressing the challenges of modern transportation, making an impact on everyday life. Together with our Automotive partners we are enriching people’s mobility experience by making their journeys faster, safer, greener, more informative and even healthier."
          label="NEUSOFT TECH"
          link="/automotive"
          image={automotive}
          tabletImage={automotiveTablet}
          icon={automotiveIcon}
          alt="automotive"
        />
      </section>
      <section id="bpo" className={clsx(classes.section, classes.backgroundGrey, classes.heightAuto)}>
        <Industry
          title={['Business Process', 'Services']}
          description="Any business needs support. We use software to help individuals, companies, and industries respond to this dynamic hyper-connected world, but we also offer Business Process Services as a strategic management tool for improving performance and value. We do Contact Center Service, IT & Technical Support, Cloud-based Maintenance, Process Management Services, Pre-sales & Data Analysis, Web Content Management, HRO, Quality Standard, Training Services."
          label="NEUSOFT SUPPORT "
          link="/business-process-services"
          image={bpo}
          tabletImage={bpoTablet}
          icon={bpoIcon}
          alt="Bpo"
          isReverse
        />
      </section>
      <section id="missions" style={{ overflow: 'visible' }} className={clsx(classes.section, classes.backgroundGrey, classes.heightAuto)}>
        <Missions />
      </section>
      <section id="our-mission" className={clsx(classes.section, classes.backgroundGrey, classes.heightAuto)}>
        <OurMission
          id="our-mission"
          text="We believe that everyone deserves amazing products. Let’s work together to transform yours."
          buttonText="GET IN TOUCH"
          image={ourMission}
          alt="ourMission"
          link={WHERE_PATH}
          settings={
            {
              backgroundButton: terracota,
              backgroundOnHoverButton: terracota,
              colorButton: white,
              colorOnHoverButton: white,
              buttonHoverShadowButton: `0px 5px 10px ${hoverButtonShadow}`,
              buttonFocusShadow: `0px 7px 15px ${stratos}`,
              paddingButton: '0.813rem 1.125rem',
              paddingMobileButton: '0.563rem 0.938rem',
              paddingTabletButton: '0.813rem 1.25rem',
              borderButton: 'unset',
            }
          }
        />
      </section>
      <section id="business-growth" className={clsx(classes.section, classes.backgroundAlabaster, classes.heightAuto)}>
        <BusinessGrowth
          id="business-growth"
          title="The magic of software"
          description="We came together from various backgrounds with one vision: to bring a lean, user-centric approach to product innovation and software development by completely understanding the business needs before building anything. Our approach to development allows us to make complex things more simple, human and fun, but magic at the same time."
          paragraph="Our software is magic even if we know how it’s done."
          buttonText="MORE ABOUT NEUSOFT"
          images={{
            web: businessGrowthWeb,
            tablet: businessGrowthTablet,
            mobile: businessGrowthMobile,
          }}
        />
      </section>
      <section id="footer" style={{ overflow: 'visible' }} className={clsx(classes.section, classes.backgroundAlabaster, classes.heightAuto)}>
        <Footer />
      </section>
      <ScrollToTop />
    </>
  );
};

export default Home;

import React, { useEffect, useState } from 'react';
import Modal from '@material-ui/core/Modal';
import PropTypes from 'prop-types';
import CookiePolicy from './CookiePolicy';
import { gdprInEnglish, gdprInRomanian } from './GDPRContent';

const GDPR = ({ state, callback }) => {
  const [openGDPR, setOpenGDPR] = useState(state);

  useEffect(() => {
    setOpenGDPR(state);
  }, [state]);

  const changeView = (closeGDPR = true) => {
    setOpenGDPR(closeGDPR);
    callback();
  };
  const [toggleValue, setToggleValue] = useState('en');
  return (
    <div>
      {openGDPR && (
        <Modal
          open
        >
          <CookiePolicy
            changeView={changeView}
            language={toggleValue}
            changeLanguage={(value) => setToggleValue(value)}
            content={toggleValue === 'ro' ? gdprInRomanian : gdprInEnglish}
            title={toggleValue === 'ro' ? 'Informare privind datele personale GDPR' : 'GDPR Data Privacy Notice'}
            displayButtons={false}
            paddingTopTitle="0.5vw"
            heightContent="80vw"
          />
        </Modal>
      )}
    </div>

  );
};

GDPR.propTypes = {
  state: PropTypes.bool,
  callback: PropTypes.func,
};

GDPR.defaultProps = {
  state: false,
  callback: () => { },
};

export default GDPR;

import React from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { malibu, white, blackRock } from '../../colors';

const styles = () => ({
  button: {
    background: (props) => props.background,
    color: (props) => props.color,
    padding: (props) => props.padding,
    border: (props) => props.border,
    borderRadius: '2.938rem',
    cursor: 'pointer',
    '&:hover': {
      color: (props) => props.colorOnHover,
      background: (props) => props.backgroundOnHover,
      boxShadow: (props) => props.buttonHoverShadow,
      border: (props) => props.borderHover,
      cursor: 'pointer',
    },
    '&:focus': {
      color: (props) => props.colorOnHover,
      background: (props) => props.backgroundOnHover,
      boxShadow: (props) => props.buttonFocusShadow,
      border: (props) => props.borderHover,
    },
    alignSelf: (props) => props.alignSelf || 'initial',
    '@media (max-width:600px)': {
      padding: (props) => `${props.paddingMobile}`,
    },
    '@media (min-width:600px) and (orientation: portrait)': {
      padding: (props) => `${props.paddingTablet}`,
    },
  },
});

const useStyles = makeStyles(styles);

const AppButton = (props) => {
  const {
    children, background,
    backgroundOnHover, color,
    colorOnHover, buttonHoverShadow,
    buttonFocusShadow,
    padding, fontSize,
    callback, alignSelf, border,
    paddingMobile, paddingTablet, borderHover,
  } = props;
  const classes = useStyles({
    background,
    backgroundOnHover,
    color,
    colorOnHover,
    buttonHoverShadow,
    buttonFocusShadow,
    padding,
    fontSize,
    alignSelf,
    border,
    borderHover,
    paddingMobile,
    paddingTablet,
  });

  return (
    <Button onClick={() => callback()} className={classes.button}>
      {children}
    </Button>
  );
};

AppButton.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
  ]),
  background: PropTypes.string,
  backgroundOnHover: PropTypes.string,
  color: PropTypes.string,
  colorOnHover: PropTypes.string,
  buttonHoverShadow: PropTypes.string,
  buttonFocusShadow: PropTypes.string,
  padding: PropTypes.string,
  paddingMobile: PropTypes.string,
  paddingTablet: PropTypes.string,
  fontSize: PropTypes.number,
  callback: PropTypes.func,
  alignSelf: PropTypes.string,
  border: PropTypes.string,
  borderHover: PropTypes.string,
};

AppButton.defaultProps = {
  children: null,
  background: malibu,
  backgroundOnHover: white,
  color: white,
  colorOnHover: blackRock,
  buttonHoverShadow: 'none',
  buttonFocusShadow: 'none',
  padding: '0.813rem 2.438rem',
  paddingMobile: '0.563rem 1.5rem',
  paddingTablet: '0.813rem 2.438rem',
  fontSize: 1,
  callback: () => { },
  alignSelf: 'initial',
  border: 'unset',
  borderHover: 'unset',
};

export default AppButton;

import React, { Suspense, lazy } from 'react';
import PropTypes from 'prop-types';

const PortraitHeader = lazy(() => import('./PortraitHeader'));
const LandscapeHeader = lazy(() => import('./LandscapeHeader'));

const Header = ({ navbarRef }) => (
  <Suspense fallback={<div>Loading...</div>}>
    <PortraitHeader navbarRef={navbarRef} />
    <LandscapeHeader navbarRef={navbarRef} />
  </Suspense>
);

Header.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }),
  navbarRef: PropTypes.shape({}),
};

Header.defaultProps = {
  location: { pathname: '/' },
  navbarRef: null,
};

export default Header;

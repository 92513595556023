/* eslint-disable react/prop-types */
import React, { useState, Suspense, useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { ParallaxProvider } from 'react-scroll-parallax';
import Home from './components/Home/Home';
import * as paths from './common/paths';
import JobTemplate from './components/JobTemplate';
import Cookies from './common/Cookies/Cookies';
import CookieConsent from './context/index';
import Header from './components/Header/Header';

const Who = React.lazy(() => import('./components/Who/Who'));
const SmartFitness = React.lazy(() => import('./components/What/SmartFitness'));
const IoT = React.lazy(() => import('./components/What/IoT'));
const Automotive = React.lazy(() => import('./components/What/Automotive'));
const BPO = React.lazy(() => import('./components/What/BPO'));
const SoftwareDevelopment = React.lazy(() => import('./components/How/SoftwareDevelopment'));
const AugmentedTeams = React.lazy(() => import('./components/How/AugmentedTeams'));
const ManagedTeams = React.lazy(() => import('./components/How/ManagedTeams'));
const BusinessProcessServices = React.lazy(() => import('./components/How/BusinessProcessServices'));
const Where = React.lazy(() => import('./components/Where/Where'));
const JoinLifeAtNeusoft = React.lazy(() => import('./components/Join/LifeAtNeusoft'));
const OpenPositions = React.lazy(() => import('./components/Join/OpenPositions'));
const Internship = React.lazy(() => import('./components/Join/Internship'));
const Imprint = React.lazy(() => import('./components/Imprint'));
const DataPolicy = React.lazy(() => import('./components/DataPolicy'));
const CookiesPolicy = React.lazy(() => import('./components/CookiesPolicy'));
const FAQ = React.lazy(() => import('./components/FAQ/index'));
const CCTVPolicy = React.lazy(() => import('./components/CCTVPolicy/index'));

const App = () => {
  const [openCookieBanner, setOpenCookieBanner] = useState();

  // https://stackoverflow.com/questions/70368760/react-uncaught-referenceerror-process-is-not-defined
  useEffect(() => {
    if (!window?.process) {
      window.process = {
        ...window?.process || {},
      };
    }
  }, []);

  return (
    <CookieConsent.Provider value={{ openCookieBanner, setOpenCookieBanner }}>
      <Cookies />
      <ParallaxProvider>
        <Suspense fallback={(
          <div style={{
            // force height to prevent page jump
            height: '105vh',
          }}
          >
            <Header />
          </div>
        )}
        >
          <Switch>
            <Route path="/" exact component={Home} />
            <Route path={paths.WHO_PATH} component={Who} />
            <Route path={paths.WHAT_FIRST_PATH} component={SmartFitness} />
            <Route path={paths.WHAT_SECOND_PATH} component={IoT} />
            <Route path={paths.WHAT_THIRD_PATH} component={Automotive} />
            <Route path={paths.WHAT_FOURTH_PATH} component={BPO} />
            <Route path={paths.HOW_FIRST_PATH} component={SoftwareDevelopment} />
            <Route path={paths.HOW_SECOND_PATH} component={AugmentedTeams} />
            <Route path={paths.HOW_THIRD_PATH} component={ManagedTeams} />
            <Route path={paths.HOW_FOURTH_PATH} component={BusinessProcessServices} />
            <Route path={paths.WHERE_PATH} component={Where} />
            <Route path={paths.JOIN_LIFE_AT_NEUSOFT_PATH} component={JoinLifeAtNeusoft} />
            <Route path={paths.JOIN_OPEN_POSITIONS_PATH} component={OpenPositions} />
            <Route path={paths.JOIN_INTERNSHIP_PATH} component={Internship} />
            <Route
              exact
              path={`${paths.OPEN_POSITION_PATH}/:id`}
              render={(props) => (
                <JobTemplate id={props.match.params.id} />
              )}
            />
            <Route path={paths.IMPRINT_PATH} component={Imprint} />
            <Route path={paths.DATA_POLICY_PATH} component={DataPolicy} />
            <Route path={paths.COOKIES_POLICY_PATH} component={CookiesPolicy} />
            <Route path={paths.FAQ_PATH} component={FAQ} />
            <Route path={paths.CCTV_PATH} component={CCTVPolicy} />
          </Switch>
        </Suspense>
      </ParallaxProvider>
    </CookieConsent.Provider>
  );
};

export default App;

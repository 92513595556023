import React from 'react';
import { makeStyles } from '@material-ui/core';
import ArrowForwardRoundedIcon from '@material-ui/icons/ArrowForwardRounded';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Dot from './Dot';
import { blackRock } from '../../colors';

const useStyles = makeStyles(() => ({
  root: {
    width: '65%',
    position: 'absolute',
    left: '0',
    bottom: '10%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: '3',
    '@media (max-width:600px)': {
      position: 'initial',
      margin: '2.313rem 0',
    },
    '@media (min-width:600px) and (orientation: portrait)': {
      position: 'initial',
      marginBottom: '4rem',
    },
    '@media (orientation:landscape) and (max-width:1180px)': {
      position: 'initial',
      marginBottom: '0',
    },
    '@media (orientation:landscape) and (max-device-width:1180px)': {
      left: '15%',
      bottom: '10%',
      justifyContent: 'flex-start',
    },
    '& svg': {
      fontSize: '1.2rem',
      margin: '0.5rem',
      '@media (max-width:600px)': {
        margin: '0.2rem',
        fontSize: '0.8rem',
      },
    },
  },
  arrow: {
    display: 'flex',
    border: `2px solid ${blackRock}`,
    borderRadius: '50%',
    transition: '300ms ease 500ms',
    height: 'auto',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  firstArrow: {
    visibility: (props) => (props.firstArrowVisibility ? 'initial' : 'hidden'),
    marginRight: '2.125rem',
    marginLeft: 0,
    '@media (orientation: portrait)': {
      '@media (hover: none) and (pointer: coarse)': {
        visibility: (props) => (props.firstArrowVisibility ? 'hidden' : 'hidden'),
      },
    },
    '@media (max-width:600px)': {
      marginRight: '0.938rem',
      padding: '0.2rem',
    },
    '@media (min-width:600px) and (orientation: portrait)': {
      marginRight: '3.313rem',
    },
  },
  secondArrow: {
    visibility: (props) => (props.secondArrowVisibility ? 'initial' : 'hidden'),
    marginLeft: '2.125rem',
    '@media (orientation: portrait)': {
      '@media (hover: none) and (pointer: coarse)': {
        visibility: (props) => (props.firstArrowVisibility ? 'hidden' : 'hidden'),
      },
    },
    '@media (max-width:600px)': {
      marginLeft: '0.938rem',
      padding: '0.2rem',
    },
    '@media (min-width:600px) and (orientation: portrait)': {
      marginLeft: '3.313rem',
    },
  },
}));

function Dots(props) {
  const {
    length, active, setActive, arrowButtonHandler,
  } = props;
  const classes = useStyles({
    firstArrowVisibility: active > 0,
    secondArrowVisibility: active < length - 1,
  });
  const getDots = () => {
    const dotsArray = [];
    for (let i = 0; i < length; i += 1) {
      dotsArray.push(<Dot key={i} isActive={active === i} />);
    }
    return dotsArray;
  };
  const forwardHandler = () => {
    setActive(active === length - 1 ? active : active + 1);
    arrowButtonHandler('forward');
  };
  const backwardHandler = () => {
    setActive(active === 0 ? active : active - 1);
    arrowButtonHandler('back');
  };

  return (
    <div className={classes.root}>
      <div
        className={clsx(classes.arrow, classes.firstArrow)}
        aria-hidden="true"
        role="button"
        onClick={backwardHandler}
      >
        <ArrowBackRoundedIcon />
      </div>
      {getDots()}
      <div
        className={clsx(classes.arrow, classes.secondArrow)}
        aria-hidden="true"
        role="button"
        onClick={forwardHandler}
      >
        <ArrowForwardRoundedIcon />
      </div>
    </div>
  );
}

Dots.propTypes = {
  length: PropTypes.number.isRequired,
  active: PropTypes.number.isRequired,
  setActive: PropTypes.func.isRequired,
  arrowButtonHandler: PropTypes.func.isRequired,
};

export default Dots;

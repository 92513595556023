import React from 'react';
import ImageAndTextSlider from '../../../common/ImageAndTextSlider';
import testimonials from '../constants';

function Testimonials() {
  return (
    <ImageAndTextSlider
      items={testimonials}
      title="They worked with us. They know us."
      settings={{ displayLine: true, logoHeight: 'auto' }}
    />
  );
}

export default Testimonials;

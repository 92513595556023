import React, { useMemo } from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { white } from '../../../colors';
import ScrollDownArrow from '../../../common/ScrollDownArrow';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: '30vh 12.96vw 0 12.96vw',
    alignItems: 'center',

    '@media(orientation: portrait)': {
      padding: '18vh 10% 0 10%',
    },
  },
  overviewTitleAndSubtitle: {
    height: '14vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  subtitle: {
    opacity: 0.5,
  },
  descriptionAndDetailsWrapper: {
    background: white,
    marginTop: '9vh',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '13.2vh',
    paddingBottom: '15vh',
    alignItems: 'center',
    width: '100%',
    position: 'relative',

    '& h3': {
      paddingTop: '5vh',
    },
  },
  overviewDescription: {
    textAlign: 'center',
    padding: '0 13vw',
  },
  detailsDescription: {
    paddingTop: '5.5vh',
    textAlign: 'justify',
    display: 'flex',
    flexDirection: 'column',
    width: '50%',

    '& p': {
      paddingBottom: '0.5vh',
    },

    '@media (orientation: portrait) and (max-width:600px)': {
      width: '80%',
    },
  },
});

function Overview({
  job, navbarRef,
}) {
  const classes = useStyles();

  if (!job) return <></>;

  const descriptionSentences = useMemo(() => job?.detailsDescription.split('\n'), [job?.detailsDescription]);

  return (
    <div className={classes.root}>
      <div className={classes.overviewTitleAndSubtitle}>
        <Typography variant="h3" component="h1">{job?.overviewTitle}</Typography>
        <Typography variant="subtitle1" className={classes.subtitle}>{job?.overviewSubtitle}</Typography>
      </div>
      <div className={classes.descriptionAndDetailsWrapper}>
        <Typography variant="body1" className={classes.overviewDescription}>{job?.overviewDescription}</Typography>
        <ScrollDownArrow navbarRef={navbarRef} />
        <Typography variant="h3">{job?.detailsTitle}</Typography>
        <div className={classes.detailsDescription}>
          {descriptionSentences.map((sentence, index) => (
            sentence?.trim() !== '' && (
              // eslint-disable-next-line react/no-array-index-key
              <Typography key={`what-you-need-to-do-${index}`} variant="body1">{`${sentence}`}</Typography>
            )
          ))}
        </div>
      </div>
    </div>
  );
}

Overview.propTypes = {
  job: PropTypes.shape({
    overviewTitle: PropTypes.string,
    overviewSubtitle: PropTypes.string,
    overviewDescription: PropTypes.string,
    detailsTitle: PropTypes.string,
    detailsDescription: PropTypes.string,
  }),
  navbarRef: PropTypes.shape({}).isRequired,
};

Overview.defaultProps = {
  job: null,
};

export default Overview;
